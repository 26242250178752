import { RequestStatus } from 'models/common.models';

import { useAppSelector } from 'store';
import { useGetRequestStatusStatus } from 'utils/requests.utils';

import { IRefereeMatchModel, IRefereeModel, IRefereeSeasonModel } from './models';
import {
  IRefereeDebutMatchResponse,
  IRefereeDetailsResponse
} from './models/refereeResponse.models';
import {
  selectGetRefereeDebutMatchRequestStatus,
  selectGetRefereeDetailsRequestStatus,
  selectGetRefereeMatchesInSeasonRequestStatus,
  selectGetRefereePhotoRequestStatus,
  selectGetRefereeSeasonRequestStatus,
  selectGetRefereeSeasonsRequestStatus,
  selectReferee,
  selectRefereeDebutMatch,
  selectRefereeDetails,
  selectRefereeMatchesInSeason,
  selectRefereePhoto,
  selectRefereeSeason,
  selectRefereeSeasons
} from './referee.selectors';

export const useSelectReferee = (id: string | undefined): IRefereeModel | undefined | null =>
  useAppSelector(state => selectReferee(state, id as string));

export const useSelectGetRefereeDetailsRequestStatus = (id: string | undefined): RequestStatus =>
  useGetRequestStatusStatus(id, selectGetRefereeDetailsRequestStatus);

export const useSelectRefereeDetails = (
  id: string | undefined
): IRefereeDetailsResponse | undefined | null =>
  useAppSelector(state => selectRefereeDetails(state, id as string));

export const useSelectGetRefereePhotoRequestStatus = (id: string | undefined): RequestStatus =>
  useGetRequestStatusStatus(id, selectGetRefereePhotoRequestStatus);

export const useSelectRefereePhoto = (id: string | undefined): string | undefined | null =>
  useAppSelector(state => selectRefereePhoto(state, id as string));

export const useSelectGetRefereeDebutMatchRequestStatus = (id: string | undefined): RequestStatus =>
  useGetRequestStatusStatus(id, selectGetRefereeDebutMatchRequestStatus);

export const useSelectRefereeDebutMatch = (
  id: string | undefined
): IRefereeDebutMatchResponse | undefined | null =>
  useAppSelector(state => selectRefereeDebutMatch(state, id as string));

export const useSelectGetRefereeSeasonsRequestStatus = (id: string | undefined): RequestStatus =>
  useGetRequestStatusStatus(id, selectGetRefereeSeasonsRequestStatus);

export const useSelectRefereeSeasons = (
  id: string | undefined
): IRefereeSeasonModel[] | undefined | null =>
  useAppSelector(state => selectRefereeSeasons(state, id as string));

export const useSelectGetRefereeSeasonRequestStatus = (
  id: string | undefined,
  seasonId: string | undefined
): RequestStatus =>
  useAppSelector(state =>
    selectGetRefereeSeasonRequestStatus(state, id as string, seasonId as string)
  ) as RequestStatus;

export const useSelectRefereeSeason = (
  id: string | undefined,
  seasonId: string | undefined
): IRefereeSeasonModel | undefined | null =>
  useAppSelector(state => selectRefereeSeason(state, id as string, seasonId as string));

export const useSelectGetRefereeMatchesInSeasonRequestStatus = (
  id: string | undefined,
  seasonId: string | undefined
): RequestStatus =>
  useAppSelector(state =>
    selectGetRefereeMatchesInSeasonRequestStatus(state, id as string, seasonId as string)
  ) as RequestStatus;

export const useSelectRefereeMatchesInSeason = (
  id: string | undefined,
  seasonId: string | undefined
): IRefereeMatchModel[] | undefined | null =>
  useAppSelector(state => selectRefereeMatchesInSeason(state, id as string, seasonId as string));
