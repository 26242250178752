import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import CircleImage from '../CircleImage';
import DefaultAvatar from './DefaultAvatar';

type Props = {
  src?: string | null;
  className?: string;
};

const Avatar = ({ src, className }: Props) => {
  const { t } = useTranslation();

  const AvatarImageTag = src ? CircleImage : DefaultAvatar;
  return <AvatarImageTag src={src!} alt={t('Avatar')} className={className} />;
};

export default memo(Avatar);
