import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import urls from 'consts/urls';
import { isNotNullAndNotUndefined, withAlternativeSign } from 'utils/utils';
import Column from 'components/Column';
import Row from 'components/Row';

import styles from './MatchResults.module.scss';
import Tooltip from './Tooltip';

type Props = {
  id: string;
  hostGoals?: number;
  guestGoals?: number;
  hostFirstHalfGoals?: number;
  guestFirstHalfGoals?: number;
  isWalkover?: boolean;
  walkoverCause?: string;
  isSmallFontSize?: boolean;
};

const MatchResults = ({
  id,
  hostGoals,
  guestGoals,
  hostFirstHalfGoals,
  guestFirstHalfGoals,
  isWalkover,
  walkoverCause,
  isSmallFontSize
}: Props) => {
  const { t } = useTranslation();

  const hostGoalsClassName = cx(styles.hostGoals, {
    [styles.hostGoals_walkower]: isWalkover,
    [styles.hostGoals_smallFontSize]: isSmallFontSize
  });

  const guestGoalsClassName = cx(styles.guestGoals, {
    [styles.guestGoals_walkower]: isWalkover,
    [styles.guestGoals_smallFontSize]: isSmallFontSize
  });

  const dividerClassName = cx(styles.divider, {
    [styles.divider_walkower]: isWalkover,
    [styles.divider_smallFontSize]: isSmallFontSize
  });

  const hostFirstHalfGoalsClassName = cx(styles.hostFirstHalfGoals, {
    [styles.hostFirstHalfGoals_smallFontSize]: isSmallFontSize
  });

  const guestFirstHalfGoalsClassName = cx(styles.guestFirstHalfGoals, {
    [styles.guestFirstHalfGoals_smallFontSize]: isSmallFontSize
  });

  const dividerFirstHalfClassName = cx(styles.firstHalfDivider, {
    [styles.firstHalfDivider_smallFontSize]: isSmallFontSize
  });

  return (
    <Link className={styles.results_container} to={urls.matchDetails(id)}>
      <Row className={styles.results_container_row}>
        <Column>
          <Row>
            <Column className={hostGoalsClassName}>{hostGoals}</Column>
            <Column className={dividerClassName}>-</Column>
            <Column className={guestGoalsClassName}>{guestGoals}</Column>
          </Row>
        </Column>

        {isWalkover ? (
          <Column>
            <Tooltip
              html={
                <>
                  {walkoverCause} || {t('NoInformationAboutWalkoverCause')}
                </>
              }
            >
              <span className={styles.walkower_shortcut}>(wo)</span>
            </Tooltip>
          </Column>
        ) : isNotNullAndNotUndefined(hostFirstHalfGoals) &&
          isNotNullAndNotUndefined(guestFirstHalfGoals) ? (
          <Column>
            <Row>
              <Column className={hostFirstHalfGoalsClassName}>
                ({withAlternativeSign(hostFirstHalfGoals)}
              </Column>
              <Column className={dividerFirstHalfClassName}>-</Column>
              <Column className={guestFirstHalfGoalsClassName}>
                {withAlternativeSign(guestFirstHalfGoals)})
              </Column>
            </Row>
          </Column>
        ) : (
          ''
        )}
      </Row>
    </Link>
  );
};

export default memo(MatchResults);
