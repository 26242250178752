import React, { memo, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';

import { useAppDispatch, useAppSelector } from 'store';
import { icons } from 'consts/icons';
import { isExactCurrentSubRoute } from 'utils/utils';

import styles from './MobileMenuLink.module.scss';
import MobileSubmenu from './MobileSubmenu';

const propTypes = {
  address: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  subLinks: PropTypes.arrayOf(PropTypes.object),
  external: PropTypes.bool
};

const MobileMenuLink = ({ address, label, subLinks, external, ...others }) => {
  const dispatch = useAppDispatch();

  const activeLinkUrl = useAppSelector(state => get(state, 'navbar.activeLinkUrl'));

  const [visibleSubmenu, setVisibleSubmenu] = useState(false);
  const [isAnySubLinkActive, setIsAnySubLinkActive] = useState(false);

  // TODO: użyć tego: const { pathname } = useLocation(); czy tak jak teraz activeLinkUrl? poniżej jest zakomentowany kod z użyciem pathname zamiastactiveLinkUrl

  const checkIsCurrentSubLinkRoute = () =>
    subLinks &&
    !isEmpty(
      [...subLinks].filter(subLink =>
        isExactCurrentSubRoute(/* pathname */ activeLinkUrl, subLink.address)
      )
    );

  const linkClassName = cx(styles.link, styles.subLink, {
    [styles.subLink_opened]: visibleSubmenu,
    [styles.link_active]: isAnySubLinkActive && !!activeLinkUrl
  });

  const activeLinkClassName = cx(styles.link_active);

  const iconClassName = cx(
    styles.subLink_icon,
    visibleSubmenu ? icons.chevronUp : icons.chevronDown
  );

  // useEffect(
  //   () => {
  //     pathname && setIsAnySubLinkActive(isCurrentSubLinkRoute());
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [pathname, dispatch]
  // );

  useEffect(() => {
    if (activeLinkUrl) {
      const isCurrentSubLinkRoute = checkIsCurrentSubLinkRoute();

      setIsAnySubLinkActive(isCurrentSubLinkRoute);

      if (isCurrentSubLinkRoute) {
        openSubmenu();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLinkUrl, dispatch]);

  const openSubmenu = () => setVisibleSubmenu(!visibleSubmenu);

  return subLinks ? (
    <li onClick={openSubmenu}>
      <span className={linkClassName} {...others}>
        {label}
        <i className={iconClassName} />
      </span>
      {subLinks && visibleSubmenu && <MobileSubmenu subLinksData={subLinks} />}
    </li>
  ) : (
    <li>
      {!external ? (
        <NavLink
          {...others}
          to={address}
          title={label}
          className={({ isActive }) => linkClassName + (isActive ? ` ${activeLinkClassName}` : '')}
        >
          {label}
        </NavLink>
      ) : (
        <a target="_blank" rel="noreferrer" href={address} title={label} className={linkClassName}>
          {label}
        </a>
      )}
    </li>
  );
};

MobileMenuLink.propTypes = propTypes;

export default memo(MobileMenuLink);
