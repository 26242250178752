import React from 'react';
import { ISimpleMatchModel } from 'models/businessCommon.models';
import styled from 'styled-components';

import { icons } from 'consts/icons';
import DateInfo from 'components/DateInfo';
import Icon from 'components/Icon';
import TeamLink from 'components/Links/TeamLink';
import MatchResults from 'components/MatchResults';
import TimeInfo from 'components/TimeInfo';
import Tooltip from 'components/Tooltip';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.light};
  border-radius: 0.5em;
  gap: 0.5rem;
`;

const SubContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
  font-size: x-small;
`;

export type Props = {
  debutMatch: ISimpleMatchModel;
};

const DebutMatch = ({ debutMatch }: Props) => (
  <Container>
    <SubContainer>
      <span>
        <TeamLink {...debutMatch?.host} reverse />
      </span>

      <span>
        <MatchResults
          id={debutMatch.id}
          hostGoals={debutMatch.hostGoals}
          guestGoals={debutMatch.guestGoals}
          hostFirstHalfGoals={debutMatch.hostFirstHalfGoals}
          guestFirstHalfGoals={debutMatch.guestFirstHalfGoals}
          isWalkover={debutMatch?.isWalkoverForGuest || debutMatch.isWalkoverForHost}
          walkoverCause={debutMatch.walkoverCause}
          isSmallFontSize
        />
      </span>

      <span>
        <TeamLink {...debutMatch?.guest} />
      </span>

      <span>
        {debutMatch?.comment && (
          <Tooltip html={debutMatch?.comment}>
            <Icon icon={icons.infoCircle} />
          </Tooltip>
        )}
      </span>
    </SubContainer>

    <SubContainer>
      <span>
        <DateInfo date={debutMatch?.date} />
      </span>

      <span>
        <TimeInfo date={debutMatch?.date} />
      </span>
    </SubContainer>

    {/* TODO: maybe add some statistics in this match? if player score goal id debut matach etc. */}
  </Container>
);

export default DebutMatch;
