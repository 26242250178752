const actionTypes = {
  STORE_HOME_PAGE_ARTICLES: 'STORE_HOME_PAGE_ARTICLES',
  STORE_HOME_PAGE_TABLE: 'STORE_HOME_PAGE_TABLE',
  SET_IS_PREVIOUS_MATCH_LOADED_FALSE: 'SET_IS_PREVIOUS_MATCH_LOADED_FALSE',
  STORE_HOME_PAGE_PREVIOUS_MATCH: 'STORE_HOME_PAGE_PREVIOUS_MATCH',
  SET_IS_NEXT_MATCH_LOADED_FALSE: 'SET_IS_NEXT_MATCH_LOADED_FALSE',
  STORE_HOME_PAGE_NEXT_MATCH: 'STORE_HOME_PAGE_NEXT_MATCH'
};

export default actionTypes;
