import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CompaniesList from 'components/CompaniesList';
import InnerContentWrapper from 'components/InnerContentWrapper';

import ArticlesList from './articles/ArticlesList';
import styles from './HomePage.module.scss';
import CardMatch from './matches/CardMatch';
import CardTable from './table/CardTable';

const propTypes = {
  articles: PropTypes.array,
  table: PropTypes.shape({ teams: PropTypes.array }),
  isPreviousMatchLoaded: PropTypes.bool,
  previousMatch: PropTypes.object,
  isNextMatchLoaded: PropTypes.bool,
  nextMatch: PropTypes.object,
  partners: PropTypes.array,
  sponsors: PropTypes.array
};

const HomePage = ({
  articles = [],
  table = { teams: [] },
  isPreviousMatchLoaded = false,
  previousMatch = {},
  isNextMatchLoaded = false,
  nextMatch = {},
  partners = [],
  sponsors = []
}) => {
  const { t } = useTranslation();

  return (
    <InnerContentWrapper>
      <div className={styles.page_wrapper}>
        <ArticlesList articles={articles} />
        <div className={styles.column_right}>
          <CardTable teams={table?.teams} />
          <CardMatch match={previousMatch} isMatchLoaded={isPreviousMatchLoaded} />
          <CardMatch nextMatch match={nextMatch} isMatchLoaded={isNextMatchLoaded} />
        </div>
      </div>
      <CompaniesList companies={partners} title={t('Partners')} className={styles.companies_list} />
      <CompaniesList companies={sponsors} title={t('Sponsors')} className={styles.companies_list} />
    </InnerContentWrapper>
  );
};

HomePage.propTypes = propTypes;

export default memo(HomePage);
