import React, { memo } from 'react';
import cx from 'classnames';

import AssistIcon from 'components/Icon/AssistIcon';
import CardRedIcon from 'components/Icon/CardRedIcon';
import CardSecondYellowIcon from 'components/Icon/CardSecondYellowIcon';
import CardYellowIcon from 'components/Icon/CardYellowIcon';
import GoalIcon from 'components/Icon/GoalIcon';
import MinutesIcon from 'components/Icon/MinutesIcon';
import PlayerLink from 'components/Links/PlayerLink';

import EventCountCell from './EventCountCell';
import { EColumnIndexes, EColumnNames } from './table.consts';
import styles from './TeamPlayersStatistics.module.scss';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  player: any; // TODO: type
  hoveredColumnIndex: number | null;
};

const TableRow = ({ player, hoveredColumnIndex }: Props) => {
  const getCellClassName = (columnName: string, columnIndex: number): string =>
    cx(styles.cell, styles[`cell_${columnName}`], {
      [styles.cell_active]: columnIndex === hoveredColumnIndex
    });

  return (
    <tr key={player.id} className={styles.table_playerRow}>
      <td className={`${getCellClassName('player', 0)} ${styles.table_playerRow_playerColumn}`}>
        <PlayerLink {...player} className={styles.table_playerRow_playerColumn_player} />
      </td>

      <EventCountCell
        icon={<MinutesIcon />}
        eventCounts={`${player.minutesInAllMatchesCount}'`}
        columnIndex={EColumnIndexes.minutesInAllMatchesCount}
        columnName={EColumnNames.minutesInAllMatchesCount}
        hoveredColumnIndex={hoveredColumnIndex}
      />
      <EventCountCell
        icon={<GoalIcon />}
        eventCounts={player.goalsInAllMatchesCount}
        columnIndex={EColumnIndexes.goalsInAllMatchesCount}
        columnName={EColumnNames.goalsInAllMatchesCount}
        hoveredColumnIndex={hoveredColumnIndex}
      />
      <EventCountCell
        icon={<GoalIcon isOwn={true} withOwnText={false} />}
        eventCounts={player.ownGoalsInAllMatchesCount}
        columnIndex={EColumnIndexes.ownGoalsInAllMatchesCount}
        columnName={EColumnNames.ownGoalsInAllMatchesCount}
        hoveredColumnIndex={hoveredColumnIndex}
      />
      <EventCountCell
        icon={<AssistIcon />}
        eventCounts={player.assistsInAllMatchesCount}
        columnIndex={EColumnIndexes.assistsInAllMatchesCount}
        columnName={EColumnNames.assistsInAllMatchesCount}
        hoveredColumnIndex={hoveredColumnIndex}
      />
      <EventCountCell
        icon={<CardYellowIcon />}
        eventCounts={player.yellowCardsInAllMatchesCount}
        columnIndex={EColumnIndexes.yellowCardsInAllMatchesCount}
        columnName={EColumnNames.yellowCardsInAllMatchesCount}
        hoveredColumnIndex={hoveredColumnIndex}
      />
      <EventCountCell
        icon={<CardSecondYellowIcon />}
        eventCounts={player.secondYellowCardsInAllMatchesCount}
        columnIndex={EColumnIndexes.secondYellowCardsInAllMatchesCount}
        columnName={EColumnNames.secondYellowCardsInAllMatchesCount}
        hoveredColumnIndex={hoveredColumnIndex}
      />
      <EventCountCell
        icon={<CardRedIcon />}
        eventCounts={player.redCardsInAllMatchesCount}
        columnIndex={EColumnIndexes.redCardsInAllMatchesCount}
        columnName={EColumnNames.redCardsInAllMatchesCount}
        hoveredColumnIndex={hoveredColumnIndex}
      />
    </tr>
  );
};

export default memo(TableRow);
