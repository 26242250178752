import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import fieldNames from 'consts/fieldNames';
import { icons } from 'consts/icons';
import MyCheckboxField from 'components/reduxFormFields/MyCheckboxField';
import MyInputEmailField from 'components/reduxFormFields/MyInputEmailField';
import MyInputPasswordField from 'components/reduxFormFields/MyInputPasswordField';
import MyInputTextField from 'components/reduxFormFields/MyInputTextField';

import FormButtonsContainer from './FormButtonsContainer';
import FormTitle from './FormTitle';
import Form from './StyledForm';

const propTypes = {
  header: PropTypes.string,
  isVisible: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showLoginForm: PropTypes.func.isRequired,
  valid: PropTypes.bool
};

const RegisterForm = ({
  header = 'Zarejestruj się', // TODO: nie działają translacje w defaultProps i tam gdzie jest użycie "i18n.t" w całej apce: i18n.t('SignUp'),
  isVisible,
  onSubmit,
  handleSubmit,
  valid,
  showLoginForm
}) => {
  const { t } = useTranslation();

  return (
    <Form handleSubmit={handleSubmit(onSubmit)} isVisible={isVisible}>
      <FormTitle>{header}</FormTitle>
      <div>
        <MyInputEmailField
          placeholder={t('Email')}
          name={fieldNames.registerForm.email}
          icon={icons.email}
          required
        />

        <MyInputTextField
          placeholder={t('Login')}
          name={fieldNames.registerForm.login}
          icon={icons.user}
          required
        />

        <MyInputPasswordField
          placeholder={t('Password')}
          name={fieldNames.registerForm.password}
          icon={icons.lock}
          required
        />

        <MyInputPasswordField
          placeholder={t('ConfirmPassword')}
          name={fieldNames.registerForm.confirmPassword}
          icon={icons.lock}
          required
        />

        <MyCheckboxField
          name={fieldNames.registerForm.acceptRegulations}
          render={() => (
            <>
              {`${t('IAccept')} `}
              <Link to="#" target="_blank">{`${t('Regulations').toLowerCase()}.`}</Link>
            </>
          )}
          required
        />
      </div>
      <FormButtonsContainer
        renderSubmitButton={SubmitButton => <SubmitButton disabled={!valid}>{header}</SubmitButton>}
        renderSecondaryButton={SwitchButton => (
          <SwitchButton onClick={showLoginForm}>{t('SignIn')}</SwitchButton>
        )}
      />
    </Form>
  );
};

RegisterForm.propTypes = propTypes;

export default memo(RegisterForm);
