export function downloadFile(blob: Blob, fileName: string): void {
  // TODO: przetestować na IE10+
  // if (window.navigator.msSaveOrOpenBlob) {
  //   // IE10+
  //   window.navigator.msSaveOrOpenBlob(blob, fileName);
  // } else {
  const a = document.createElement('a');
  document.body.append(a);
  a.setAttribute('style', '"display: none;');
  const fileURL = URL.createObjectURL(blob);
  a.href = fileURL;
  a.download = fileName;
  // TODO: or here: document.body.append(a); czy jak jest tam wyżej to też przypiszą się fileURL i fileName? czy trzeba dopiero tutaj dodać do body żeby były te property
  a.click();
  window.URL.revokeObjectURL(fileURL);
  // TODO: or:
  // setTimeout(() => {
  //   document.body.removeChild(a);
  //   window.URL.revokeObjectURL(fileURL);
  // }, 0);
  // }
}

export function downloadPdf(pdfData: { data: string }, fileName: string): void {
  // const byteCharacters = atob(pdfData.data); // TODO: deprecated, czy ta linia poniżej robi to samo?
  const byteCharacters = Buffer.from(pdfData.data, 'base64').toString();

  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'application/pdf' });

  downloadFile(blob, fileName);
}

export function openWindowWithPDF(pdf: Blob | string): void {
  const fileURL = URL.createObjectURL(pdf as Blob);
  window.open(fileURL, '_blank', 'noreferrer');
}
