import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { loadingSkeleton, placeholderImageLoading } from 'styling';

const SkeletonStyled = styled.div<Props>`
  position: relative;
  overflow: hidden;
  line-height: 1;
  margin: 0.2rem;

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-image: ${({ theme }) =>
      `linear-gradient(90deg, ${theme.darkPlaceholderColor}, #f5f5f5, ${theme.darkPlaceholderColor})`};

    ${({ secondVariant }) =>
      secondVariant
        ? css`
            animation: ${placeholderImageLoading} 1.5s ease-in-out infinite;
          `
        : css`
            transform: translateX(-100%);
            animation: ${loadingSkeleton} 1.5s ease-in-out infinite;
          `}
  }
`;

type Props = {
  width?: string;
  height?: string;
  backgroundColor?: string;
  borderRadius?: string;
  isCircle?: boolean;
  isBlock?: boolean;
  secondVariant?: boolean;
  style?: React.CSSProperties;
  as?: React.ElementType;
};

const Skeleton = ({
  width = '10rem',
  height = '1.5em',
  backgroundColor = '#bcbcbc',
  borderRadius = '0.25rem',
  isCircle = false,
  isBlock = true,
  secondVariant = true,
  style = {},
  as: Tag = 'div'
}: Props) => (
  <SkeletonStyled
    as={Tag}
    secondVariant={secondVariant}
    style={{
      width,
      height,
      backgroundColor,
      borderRadius: isCircle ? '50%' : borderRadius,
      display: isBlock ? 'block' : 'inline-block',
      ...style
    }}
  >
    &zwnj;
  </SkeletonStyled>
);

export default memo(Skeleton);
