import React, { memo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props = { children: any; label: string; required: boolean };

const FormField = ({ children, label, required }: Props) => (
  <label className="">
    <span>
      {label}
      {required && <span className="">*</span>}
    </span>
    {children}
  </label>
);

export default memo(FormField);
