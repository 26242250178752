import React from 'react';
import { useTranslation } from 'react-i18next';
import { RequestStatus } from 'models/common.models';

import DebutMatch from 'components/DebutMatch';
import TeamLink from 'components/Links/TeamLink';
import PersonDetailsCard from 'components/PersonDetailsCard';
import SingleInformationMultiline from 'components/SingleInformationMultiline';

import { ICoachCurrentTeamModel, ICoachDebutMatchModel, ICoachDetailsModel } from './data/models';

type Props = {
  details?: ICoachDetailsModel | null;
  photo?: string | null;
  currentTeam?: ICoachCurrentTeamModel | null;
  debutMatch?: ICoachDebutMatchModel | null;

  getDetailsRequestStatus?: RequestStatus | null;
  getPhotoRequestStatus?: RequestStatus | null;
  getCurrentTeamRequestStatus?: RequestStatus | null;
  getDebutMatchRequestStatus?: RequestStatus | null;
};

const CoachDetailsCard = ({
  details,
  photo,
  currentTeam,
  debutMatch,
  getDetailsRequestStatus,
  getPhotoRequestStatus,
  getCurrentTeamRequestStatus,
  getDebutMatchRequestStatus
}: Props) => {
  const { t } = useTranslation();

  return (
    <PersonDetailsCard
      photo={photo}
      firstName={details?.firstName}
      lastName={details?.lastName}
      badgeLabel={t('Coach')}
      isPhotoLoading={getPhotoRequestStatus?.isLoading}
      isDetailsLoading={getDetailsRequestStatus?.isLoading}
    >
      <SingleInformationMultiline
        title={`${t('Team')} ${t('InCurrentSeason')}`}
        value={
          currentTeam ? (
            <TeamLink {...currentTeam} withEmphasisMyTeam={false} />
          ) : (
            <div>{t('NoData')}</div>
          )
        }
        isLoading={getCurrentTeamRequestStatus?.isLoading}
      />

      <SingleInformationMultiline
        title={t('SeniorMatchDebutAsFirstTrainer')}
        value={debutMatch ? <DebutMatch debutMatch={debutMatch} /> : <div>{t('NoData')}</div>}
        isLoading={getDebutMatchRequestStatus?.isLoading}
      />
    </PersonDetailsCard>
  );
};

export default CoachDetailsCard;
