import React, { memo } from 'react';
import cx from 'classnames';

import { useIsDesktop } from 'utils/customHooks';
import Column from 'components/Column';
import AssistIcon from 'components/Icon/AssistIcon';
import CardRedIcon from 'components/Icon/CardRedIcon';
import CardSecondYellowIcon from 'components/Icon/CardSecondYellowIcon';
import CardYellowIcon from 'components/Icon/CardYellowIcon';
import GoalIcon from 'components/Icon/GoalIcon';
import MinutesIcon from 'components/Icon/MinutesIcon';
import TeamLink from 'components/Links/TeamLink';
import Row from 'components/Row';
import Skeleton from 'components/Skeleton';

import { ILeagueModel } from '../data/models';
import EventCountColumn from './EventCountColumn';
import styles from './TeamsRows.module.scss';

type Props = {
  league: ILeagueModel;
};

const TeamsRows = ({ league }: Props) => {
  const isDesktop = useIsDesktop();

  const teamRowClassName = cx(styles.team_row, {
    [styles.team_row_mobile]: !isDesktop
  });

  const ageGroupNameColumnClassName = cx(styles.ageGroupName_column, {
    [styles.ageGroupName_column_mobile]: !isDesktop
  });

  const teamNameColumnClassName = cx(styles.teamName_column, {
    [styles.teamName_column_mobile]: !isDesktop
  });

  const eventCountColumnClassName = cx(styles.events_count_column, {
    [styles.events_count_column_mobile]: !isDesktop
  });

  const teamsRows = league.teams?.map(team => (
    <Row key={team.id} className={teamRowClassName}>
      <Column className={ageGroupNameColumnClassName} width={isDesktop ? '15%' : '100%'}>
        {team.ageGroupName}
      </Column>

      <Column className={teamNameColumnClassName} width={isDesktop ? '25%' : '100%'}>
        <TeamLink {...team} />
      </Column>

      <Column className={eventCountColumnClassName} width={isDesktop ? '60%' : '100%'}>
        <Row className={styles.statistics_row}>
          {team.getSeasonStatisticsRequestStatus?.isLoading ? (
            <Skeleton width="20rem" />
          ) : (
            <>
              <EventCountColumn
                icon={<MinutesIcon />}
                eventCounts={`${team.playerStatistics?.minutesCount}'`}
              />
              <EventCountColumn
                icon={<GoalIcon />}
                eventCounts={team.playerStatistics?.goalsCount}
              />
              <EventCountColumn
                icon={<GoalIcon isOwn={true} withOwnText={false} />}
                eventCounts={team.playerStatistics?.ownGoalsCount}
              />
              <EventCountColumn
                icon={<AssistIcon />}
                eventCounts={team.playerStatistics?.assistsCount}
              />
              <EventCountColumn
                icon={<CardYellowIcon />}
                eventCounts={team.playerStatistics?.yellowCardsCount}
              />
              <EventCountColumn
                icon={<CardSecondYellowIcon />}
                eventCounts={team.playerStatistics?.secondYellowCardsCount}
              />
              <EventCountColumn
                icon={<CardRedIcon />}
                eventCounts={team.playerStatistics?.redCardsCount}
              />
            </>
          )}
        </Row>
      </Column>
    </Row>
  ));

  return teamsRows;
};

export default memo(TeamsRows);
