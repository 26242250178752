import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ISimpleTeamModel } from 'models/businessCommon.models';

import { toDateTimeFormat } from 'utils/dateFormat';

type Props = {
  host: ISimpleTeamModel;
  guest: ISimpleTeamModel;
  date: Date;
};

const NextMatchInfo = ({ host, guest, date }: Props) => {
  const { t } = useTranslation();

  const hasBothTeamNames = host.name && guest.name;
  const informationToDisplay = hasBothTeamNames ? `${host.name} - ${guest.name}` : t('NoData');

  return (
    <>
      <div>{informationToDisplay}</div>
      <span>{toDateTimeFormat(date)}</span>
    </>
  );
};

export default memo(NextMatchInfo);
