import React, { memo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { useScrollToUp } from 'utils/customHooks';

import Header from './Header';
import StyledContentWrapper from './StyledContentWrapper';
import StyledFooter from './StyledFooter';
import StyledWrapper from './StyledWrapper';

const propTypes = {
  children: PropTypes.node.isRequired
};

const WebsiteWrapper = ({ children }) => {
  useScrollToUp();

  return (
    <StyledWrapper>
      <Header />
      <StyledContentWrapper>
        {children}
        <StyledFooter>Copyright © {dayjs().year()} Błażowianka Błażowa</StyledFooter>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

WebsiteWrapper.propTypes = propTypes;

export default memo(WebsiteWrapper);
