import ArrayUtils from 'utils/array.utils';
import ObjectUtils from 'utils/object.utils';

import actionTypes from './actionTypes';
import service from './service';

// note: unused
export function get() {
  return dispatch =>
    service.get().then(data =>
      dispatch({
        type: actionTypes.STORE_HOME_PAGE_DATA,
        data
      })
    );
}

export function getArticles() {
  return (dispatch, getState) => {
    const { home } = getState();

    return (
      ArrayUtils.isEmpty(home.articles) &&
      service.getArticles().then(data =>
        dispatch({
          type: actionTypes.STORE_HOME_PAGE_ARTICLES,
          data
        })
      )
    );
  };
}

export function getTable() {
  return (dispatch, getState) => {
    const { home } = getState();

    return (
      ObjectUtils.isEmpty(home.table) &&
      service.getTable().then(data =>
        dispatch({
          type: actionTypes.STORE_HOME_PAGE_TABLE,
          data
        })
      )
    );
  };
}

export function getPreviousMatch() {
  return (dispatch, getState) => {
    const { home } = getState();

    if (ObjectUtils.isEmpty(home.previousMatch)) {
      dispatch({
        type: actionTypes.SET_IS_PREVIOUS_MATCH_LOADED_FALSE
      });

      return service.getPreviousMatch().then(data =>
        dispatch({
          type: actionTypes.STORE_HOME_PAGE_PREVIOUS_MATCH,
          data
        })
      );
    }
  };
}

export function getNextMatch() {
  return (dispatch, getState) => {
    const { home } = getState();

    if (ObjectUtils.isEmpty(home.nextMatch)) {
      dispatch({
        type: actionTypes.SET_IS_NEXT_MATCH_LOADED_FALSE
      });

      return service.getNextMatch().then(data =>
        dispatch({
          type: actionTypes.STORE_HOME_PAGE_NEXT_MATCH,
          data
        })
      );
    }
  };
}
