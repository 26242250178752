import React, { memo } from 'react';
import { ISimpleTeamModel } from 'models/businessCommon.models';

import { sortByDateDescending } from 'utils/sortingMethods';
import Row from 'components/Row';

import LastMatch from './LastMatch';
import styles from './Matches.module.scss';
import NextMatch from './NextMatch';

type Props = {
  lastFiveMatches: {
    id: string;
    host: ISimpleTeamModel;
    guest: ISimpleTeamModel;
    hostGoals: number;
    guestGoals: number;
    date: Date;
    status: string;
  }[];
  nextMatch: {
    id: string;
    host: ISimpleTeamModel;
    guest: ISimpleTeamModel;
    date: Date;
  };
  setActiveTeamId: (teamId: string) => void;
  clearActiveTeamId: () => void;
};

const Matches = ({ nextMatch, lastFiveMatches, setActiveTeamId, clearActiveTeamId }: Props) => {
  const lastFiveMatchesResult = lastFiveMatches
    ? [...lastFiveMatches]
        .sort(sortByDateDescending)
        .map(match => (
          <LastMatch
            key={match.id}
            id={match.id}
            host={match.host}
            guest={match.guest}
            date={match.date}
            hostGoals={match.hostGoals}
            guestGoals={match.guestGoals}
            status={match.status}
            setActiveTeamId={setActiveTeamId}
            clearActiveTeamId={clearActiveTeamId}
          />
        ))
    : [];

  return (
    <Row>
      {nextMatch ? (
        <NextMatch
          key={nextMatch.id}
          id={nextMatch.id}
          host={nextMatch.host}
          guest={nextMatch.guest}
          date={nextMatch.date}
          setActiveTeamId={setActiveTeamId}
          clearActiveTeamId={clearActiveTeamId}
        />
      ) : (
        <div className={styles.emptyNextMatchDiv}></div>
      )}
      {lastFiveMatchesResult}
    </Row>
  );
};

export default memo(Matches);
