import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

import fieldNames from 'consts/fieldNames';
import formNames from 'consts/formNames';
import urls from 'consts/urls';
import { areEquals, isEmail, isRequiredArray, isValidPasswordArray } from 'utils/formValidators';
import Button from 'components/Button';
import Form from 'components/Form';
import MyInputEmailField from 'components/reduxFormFields/MyInputEmailField';
import MyInputPasswordField from 'components/reduxFormFields/MyInputPasswordField';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired
};

const SetNewPasswordForm = ({ handleSubmit, submitting, submitSucceeded }) => {
  const { t } = useTranslation();

  return (
    <div>
      {submitSucceeded ? (
        <div>
          <p>{t('YourPasswordWasChanged')}</p>
          <p>
            <Link to={urls.login}>{t('LoginPage')}</Link>
          </p>
        </div>
      ) : (
        <Form handleSubmit={handleSubmit}>
          <MyInputEmailField label={t('Email')} placeholder={t('Email')} name="email" />

          <MyInputPasswordField
            label={t('NewPassword')}
            placeholder={t('NewPassword')}
            name="newPassword"
          />

          <MyInputPasswordField
            label={t('RepeatPassword')}
            placeholder={t('RepeatPassword')}
            name="repeatPassword"
          />

          <Button disabled={submitting}>{t('SetNewPassword')}</Button>
        </Form>
      )}
    </div>
  );
};

SetNewPasswordForm.propTypes = propTypes;

const validate = values => {
  const errors = {};

  isRequiredArray(
    errors,
    values,
    [
      fieldNames.setNewPasswordForm.email,
      fieldNames.setNewPasswordForm.newPassword,
      fieldNames.setNewPasswordForm.confirmPassword
    ],
    t('FieldRequired')
  );

  isEmail(errors, values, fieldNames.setNewPasswordForm.email, t('FieldMustBeValidEmail'));

  areEquals(
    errors,
    values,
    [fieldNames.setNewPasswordForm.newPassword, fieldNames.setNewPasswordForm.confirmPassword],
    t('PasswordsNotEquals')
  );

  isValidPasswordArray(
    errors,
    values,
    [fieldNames.setNewPasswordForm.newPassword, fieldNames.setNewPasswordForm.confirmPassword],
    t('NotValidPassword')
  );

  return errors;
};

export default reduxForm({
  form: formNames.setNewPasswordForm,
  validate
})(memo(SetNewPasswordForm));
