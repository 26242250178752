import React, { memo, ReactNode } from 'react';
import cx from 'classnames';

import { colorsType, EColors } from 'consts/colors';

import ContentRevealer from '../ContentRevealer';
import styles from './CardFooter.module.scss';

type Props = { isVisible: boolean; background: colorsType; children: ReactNode };

const CardFooter = ({ isVisible, background = EColors.green, children }: Props) => {
  const footerClassName = cx(styles.footer, {
    [`background-${background}`]: background
  });

  return (
    <ContentRevealer
      isVisible={isVisible}
      className={footerClassName}
      visibleClassName={styles.footer_visible}
    >
      {children}
    </ContentRevealer>
  );
};

export default memo(CardFooter);
