import React, { memo } from 'react';
import { ISimpleTeamModel } from 'models/businessCommon.models';

import { withAlternativeSign } from 'utils/utils';
import Column from 'components/Column';
import DateInfo from 'components/DateInfo';
import Row from 'components/Row';
import TimeInfo from 'components/TimeInfo';

type Props = {
  host: ISimpleTeamModel;
  guest: ISimpleTeamModel;
  hostGoals: number;
  guestGoals: number;
  date: Date;
};

const LastMatchInfo = ({ host, guest, hostGoals, guestGoals, date }: Props) => (
  <Column>
    <Row>
      {`${withAlternativeSign(hostGoals)}:${withAlternativeSign(guestGoals)} (${host.name} - ${
        guest.name
      })`}
    </Row>
    <Row justifyContent="center">
      <DateInfo date={date} withoutIcon />
    </Row>
    <Row justifyContent="center">
      <TimeInfo date={date} withoutIcon />
    </Row>
  </Column>
);

export default memo(LastMatchInfo);
