// TODO: Vorbert -> wymyślić mechanizm wyrzucający "fas" z początka, zawsze by to zaoszczedziło kilka KB danych ;)

export const icons = {
  arrowRight: 'fas fa-arrow-right',
  arrowUp: 'fas fa-long-arrow-alt-up',
  arrowDown: 'fas fa-long-arrow-alt-down',
  shortArrowUp: 'fas fa-arrow-up',
  shortArrowDown: 'fas fa-arrow-down',
  chevronRight: 'fas fa-chevron-right',
  chevronUp: 'fas fa-chevron-up',
  chevronDown: 'fas fa-chevron-down',
  cross: 'fas fa-times',
  login: 'fas fa-sign-in-alt',
  tools: 'fas fa-tools',
  crown: 'fas fa-crown',
  ball: 'far fa-futbol',
  exchange: 'fas fa-exchange-alt',
  assisting: 'far fa-handshake',
  copy: 'fas fa-copy',
  user: 'fas fa-user',
  football: 'fas fa-futbol',
  return: 'fas fa-undo-alt',
  calendar: 'far fa-calendar-alt',
  clock: 'far fa-clock',
  userOutline: 'far fa-user',
  check: 'fas fa-check',
  caretRight: 'fas fa-caret-right',
  lock: 'fas fa-lock',
  email: 'fas fa-at',
  plus: 'fas fa-plus',
  thumbsUp: 'far fa-thumbs-up',
  handHoldingHeart: 'fas fa-hand-holding-heart',
  handSparkles: 'fas fa-hand-sparkles',
  walking: 'fas fa-walking',
  userPlus: 'fas fa-user-plus',
  handPointing: 'fas fa-hand-point-right',
  lightbulb: 'fas fa-lightbulb',
  infoCircle: 'fas fa-info-circle',
  ban: 'fas fa-ban',
  image: 'fas fa-image'
};

export enum EIcons {
  arrowRight = 'fas fa-arrow-right',
  arrowUp = 'fas fa-long-arrow-alt-up',
  arrowDown = 'fas fa-long-arrow-alt-down',
  shortArrowUp = 'fas fa-arrow-up',
  shortArrowDown = 'fas fa-arrow-down',
  chevronRight = 'fas fa-chevron-right',
  chevronUp = 'fas fa-chevron-up',
  chevronDown = 'fas fa-chevron-down',
  cross = 'fas fa-times',
  login = 'fas fa-sign-in-alt',
  tools = 'fas fa-tools',
  crown = 'fas fa-crown',
  ball = 'far fa-futbol',
  exchange = 'fas fa-exchange-alt',
  assisting = 'far fa-handshake',
  copy = 'fas fa-copy',
  user = 'fas fa-user',
  football = 'fas fa-futbol',
  return = 'fas fa-undo-alt',
  calendar = 'far fa-calendar-alt',
  clock = 'far fa-clock',
  userOutline = 'far fa-user',
  check = 'fas fa-check',
  caretRight = 'fas fa-caret-right',
  lock = 'fas fa-lock',
  email = 'fas fa-at',
  plus = 'fas fa-plus',
  thumbsUp = 'far fa-thumbs-up',
  handHoldingHeart = 'fas fa-hand-holding-heart',
  handSparkles = 'fas fa-hand-sparkles',
  walking = 'fas fa-walking',
  userPlus = 'fas fa-user-plus',
  handPointing = 'fas fa-hand-point-right',
  lightbulb = 'fas fa-lightbulb',
  infoCircle = 'fas fa-info-circle',
  ban = 'fas fa-ban',
  image = 'fas fa-image'
}
