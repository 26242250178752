import { KeyValuePair } from 'models/common.models';

import ArrayUtils from 'utils/array.utils';

import { ILeagueModel, IRefereeModel, IRefereeSeasonModel, RefereesState } from './models';

export function getExistingReferee(
  state: RefereesState,
  refereeId: string
): IRefereeModel | undefined | null {
  return state[refereeId];
}

export function getExistingSeason(
  state: RefereesState,
  refereeId: string,
  seasonId: string
): IRefereeSeasonModel | undefined | null {
  const existingReferee = getExistingReferee(state, refereeId);

  if (existingReferee?.seasons?.length) {
    return existingReferee?.seasons.find(season => season.seasonId === seasonId);
  }
}

export function getExistingLeague(
  state: RefereesState,
  refereeId: string,
  seasonId: string,
  leagueId: string
): ILeagueModel | undefined | null {
  const existingSeason = getExistingSeason(state, refereeId, seasonId);

  if (existingSeason) {
    return existingSeason?.leagues.find(league => league.leagueId === leagueId);
  }
}

export function addNewReferee(state: RefereesState, action, keyValues: KeyValuePair[]): void {
  const refereeId = action.meta.arg;

  state[refereeId] = {} as IRefereeModel;

  for (const keyValue of keyValues) {
    state[refereeId][keyValue.key] = keyValue.value;
  }
}

export function changeRefereeValuesOrAddNewReferee(
  state: RefereesState,
  action,
  keyValues: KeyValuePair[]
): void {
  const refereeId = action.meta.arg;

  const existingReferee = getExistingReferee(state, refereeId);

  if (existingReferee) {
    for (const keyValue of keyValues) {
      existingReferee[keyValue.key] = keyValue.value;
    }
  } else {
    state[refereeId] = {} as IRefereeModel;

    for (const keyValue of keyValues) {
      state[refereeId][keyValue.key] = keyValue.value;
    }
  }
}

export function changeSeasonValuesOrAddNewSeason(
  state: RefereesState,
  action,
  keyValues: KeyValuePair[]
): void {
  const refereeId = action.meta.arg.id;
  const seasonId = action.meta.arg.seasonId;

  const existingSeason = getExistingSeason(state, refereeId, seasonId);

  if (existingSeason) {
    for (const keyValue of keyValues) {
      existingSeason[keyValue.key] = keyValue.value;
    }
  } else {
    if (ArrayUtils.isEmpty(state[refereeId].seasons!)) {
      state[refereeId].seasons = [];
    }

    state[refereeId].seasons!.push({ seasonId } as IRefereeSeasonModel);

    const existingSeason = getExistingSeason(state, refereeId, seasonId);

    for (const keyValue of keyValues) {
      existingSeason![keyValue.key] = keyValue.value;
    }
  }
}
