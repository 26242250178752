import i18n from 'i18n';

import timeTypes from 'consts/timeTypes';

const getComplexDataText = (dataUnit: string, number: number): string => {
  switch (number) {
    case 1:
      return `${dataUnit}a`;
    case 2:
    case 22:
    case 3:
    case 23:
      return `${dataUnit}y`;
    default:
      return dataUnit;
  }
};

export const getDaysText = (number: number): string => {
  switch (number) {
    case 1:
      return 'dzień';
    default:
      return 'dni';
  }
};

export const getHoursText = (number: number): string => getComplexDataText('godzin', number);

export const getMinutesText = (number: number): string => getComplexDataText('minut', number);

export const getSecondsText = (number: number): string => getComplexDataText('sekund', number);

export const getTimeText = (type: string, number: number): string => {
  switch (type) {
    case timeTypes.days:
      return getDaysText(number);
    case timeTypes.hours:
      return getHoursText(number);
    case timeTypes.minutes:
      return getMinutesText(number);
    default:
      return getSecondsText(number);
  }
};

// TODO: check differences between getPolishCountdownText and getNonPolishCountdownText methods, why are two instead one universally with translations?
// TODO: eslint rule when return type in function or in const lambda arrow functions is missing

export const getPolishCountdownText = (
  days: number,
  hours: number,
  minutes: number,
  seconds: number
): string =>
  `(${i18n.t('WillStartIn').toLowerCase()} ${days} ${getTimeText(
    timeTypes.days,
    days
  )} ${hours} ${getTimeText(timeTypes.hours, hours)} ${minutes} ${getTimeText(
    timeTypes.minutes,
    minutes
  )} ${seconds} ${getTimeText(timeTypes.seconds, seconds)})`;

export const getNonPolishCountdownText = (
  days: number,
  hours: number,
  minutes: number,
  seconds: number
): string =>
  `(${i18n.t('WillStartIn').toLowerCase()} ${days} ${i18n.t('Days').toLowerCase()} ${hours} ${i18n
    .t('Hours')
    .toLowerCase()} ${minutes} ${i18n.t('Minutes').toLowerCase()} ${seconds} ${i18n
    .t('Seconds')
    .toLowerCase()})`;

export const getCountdownText = (
  days: number,
  hours: number,
  minutes: number,
  seconds: number,
  isPolish = true
): string =>
  isPolish
    ? getPolishCountdownText(days, hours, minutes, seconds)
    : getNonPolishCountdownText(days, hours, minutes, seconds);

export default getTimeText;
