import React, { memo, ReactNode, useEffect, useState } from 'react';
import cx from 'classnames';

import { colorsType } from 'consts/colors';
import { icons } from 'consts/icons';
import Card from 'components/Card';
import ContentRevealer from 'components/ContentRevealer';
import Row from 'components/Row';

import styles from './Accordion.module.scss';

// TODO: when Card components chagne to typescript
// interface Props extends Omit<CardProps, 'renderFooter' | 'renderTitle'> {
type Props = {
  title: string;
  footer?: ReactNode;
  extendedOnInit?: boolean;
  withoutPadding: boolean;
  isStyleDisplayContents: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;

  className?: string;
  hoverable?: boolean;
  hoverableCursor?: boolean;
  onHoverChange?: () => void;
  autoHeight?: boolean;
  autoWidth?: boolean;
  alignCenter?: boolean;
  renderFooter?: () => void;
  footerBackground?: colorsType;
  renderTitle?: () => void;
  titleBackground?: colorsType;
  link?: {
    to: string;
    title: string;
    target: string;
  };
};

const Accordion = ({
  title,
  footer,
  extendedOnInit = true,
  withoutPadding,
  isStyleDisplayContents,

  // props from Card
  children,
  className,
  hoverable,
  hoverableCursor,
  onHoverChange,
  autoHeight,
  autoWidth,
  alignCenter,
  footerBackground,
  titleBackground,
  link
}: Props) => {
  const [isExtended, setIsExtended] = useState(extendedOnInit);
  const toggleIsExtended = () => setIsExtended(!isExtended);

  const accordionClassName = cx(styles.accordion, className);

  const iconClassName = cx(icons.chevronDown, {
    [styles.rotated]: isExtended
  });

  const bodyClassName = cx({
    [styles.accordion_body]: isExtended,
    [styles.accordion_body_collapsed]: !isExtended || withoutPadding
  });

  useEffect(() => {
    if (extendedOnInit !== isExtended) {
      toggleIsExtended();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extendedOnInit]);

  return (
    <Card
      className={accordionClassName}
      hoverable={hoverable}
      hoverableCursor={hoverableCursor}
      onHoverChange={onHoverChange}
      autoHeight={autoHeight}
      autoWidth={autoWidth}
      alignCenter={alignCenter}
      footerBackground={footerBackground}
      titleBackground={titleBackground}
      link={link}
      renderTitle={() => (
        <Row className={styles.accordion_title} onClick={toggleIsExtended}>
          <h3>{title}</h3>
          {children && <i className={iconClassName} />}
        </Row>
      )}
      renderFooter={footer ? () => footer : null} // TODO: to check type, if working etc.
    >
      <ContentRevealer
        isVisible={isExtended}
        className={bodyClassName}
        isStyleDisplayContents={isStyleDisplayContents}
      >
        <div>{children}</div>
      </ContentRevealer>
    </Card>
  );
};

export default memo(Accordion);
