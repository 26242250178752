import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ESortDirections } from 'consts/ESortDirections';

import CardTableHeaderCell from './CardTableHeaderCell';
import { EColumnIndexes, EColumnNames } from './table.consts';

type Props = {
  sortDirection: ESortDirections | null;
  sortedColumnIndex: number;
  setHoveredColumnIndex: (columnIndex: number | null) => void;
  sortColumn: (columnIndex: number) => void;
};

const CardTableHeader = ({
  sortDirection,
  sortedColumnIndex,
  setHoveredColumnIndex,
  sortColumn
}: Props) => {
  const { t } = useTranslation();

  const handleOnMouseLeave = () => setHoveredColumnIndex(null);

  return (
    <thead onMouseLeave={handleOnMouseLeave}>
      <tr>
        <CardTableHeaderCell
          columnIndex={EColumnIndexes.position}
          columnName={EColumnNames.position}
          sortedColumnIndex={sortedColumnIndex}
          sortDirection={sortDirection}
          setHoveredColumnIndex={setHoveredColumnIndex}
          sortColumn={sortColumn}
        >
          {t('OrdinalNumberAbbreviation')}
        </CardTableHeaderCell>
        <CardTableHeaderCell
          columnIndex={EColumnIndexes.name}
          columnName={EColumnNames.name}
          sortedColumnIndex={sortedColumnIndex}
          sortDirection={sortDirection}
          setHoveredColumnIndex={setHoveredColumnIndex}
          sortColumn={sortColumn}
        >
          {t('Team')}
        </CardTableHeaderCell>

        <CardTableHeaderCell
          columnIndex={EColumnIndexes.matchesPlayedNumber}
          columnName={EColumnNames.matchesPlayedNumber}
          sortedColumnIndex={sortedColumnIndex}
          sortDirection={sortDirection}
          setHoveredColumnIndex={setHoveredColumnIndex}
          sortColumn={sortColumn}
        >
          {t('PlayedMatchesQuantityAbbreviation')}
        </CardTableHeaderCell>

        <CardTableHeaderCell
          columnIndex={EColumnIndexes.points}
          columnName={EColumnNames.points}
          sortedColumnIndex={sortedColumnIndex}
          sortDirection={sortDirection}
          setHoveredColumnIndex={setHoveredColumnIndex}
          sortColumn={sortColumn}
        >
          {t('ScoredPointsQuantityAbbreviation')}
        </CardTableHeaderCell>

        <CardTableHeaderCell
          columnIndex={EColumnIndexes.goalsScored}
          columnName={EColumnNames.goalsScored}
          sortedColumnIndex={sortedColumnIndex}
          sortDirection={sortDirection}
          setHoveredColumnIndex={setHoveredColumnIndex}
          sortColumn={sortColumn}
        >
          {t('ScoredGoalsQuantityAbbreviation')}
        </CardTableHeaderCell>

        <CardTableHeaderCell
          columnIndex={EColumnIndexes.goalsLost}
          columnName={EColumnNames.goalsLost}
          sortedColumnIndex={sortedColumnIndex}
          sortDirection={sortDirection}
          setHoveredColumnIndex={setHoveredColumnIndex}
          sortColumn={sortColumn}
        >
          {t('LostGoalsQuantityAbbreviation')}
        </CardTableHeaderCell>

        <CardTableHeaderCell
          columnIndex={EColumnIndexes.goalsDifference}
          columnName={EColumnNames.goalsDifference}
          sortedColumnIndex={sortedColumnIndex}
          sortDirection={sortDirection}
          setHoveredColumnIndex={setHoveredColumnIndex}
          sortColumn={sortColumn}
        >
          {t('GoalsQuantityDifferenceAbbreviation')}
        </CardTableHeaderCell>
      </tr>
    </thead>
  );
};

export default memo(CardTableHeader);
