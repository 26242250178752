import { createSelector } from '@reduxjs/toolkit';
import { RequestStatusType } from 'models/common.models';

import { RootState } from 'store';

import { getExistingCoach, getExistingSeason } from './coach.functions';
import { CoachesState, ICoachMatchModel, ICoachModel, ICoachSeasonModel } from './models';
import {
  ICoachCurrentTeamResponse,
  ICoachDebutMatchResponse,
  ICoachDetailsResponse
} from './models/coachResponse.models';

const selectSelf = (state: RootState) => state.coaches;
const getId = (_state: RootState, id: string) => id;
const getSeasonId = (_state: RootState, _id: string, seasonId: string) => seasonId;

export const selectCoach = createSelector(
  selectSelf,
  getId,
  (state: CoachesState, id: string): ICoachModel | undefined | null => getExistingCoach(state, id)
);

export const selectGetCoachDetailsRequestStatus = createSelector(
  selectSelf,
  getId,
  (state: CoachesState, id: string): RequestStatusType =>
    getExistingCoach(state, id)?.getDetailsRequestStatus
);

export const selectCoachDetails = createSelector(
  selectSelf,
  getId,
  (state: CoachesState, id: string): ICoachDetailsResponse | undefined | null =>
    getExistingCoach(state, id)?.details
);

export const selectGetCoachPhotoRequestStatus = createSelector(
  selectSelf,
  getId,
  (state: CoachesState, id: string): RequestStatusType =>
    getExistingCoach(state, id)?.getPhotoRequestStatus
);

export const selectCoachPhoto = createSelector(
  selectSelf,
  getId,
  (state: CoachesState, id: string): string | undefined | null => getExistingCoach(state, id)?.photo
);

export const selectGetCoachCurrentTeamRequestStatus = createSelector(
  selectSelf,
  getId,
  (state: CoachesState, id: string): RequestStatusType =>
    getExistingCoach(state, id)?.getCurrentTeamRequestStatus
);

export const selectCoachCurrentTeam = createSelector(
  selectSelf,
  getId,
  (state: CoachesState, id: string): ICoachCurrentTeamResponse | undefined | null =>
    getExistingCoach(state, id)?.currentTeam
);

export const selectGetCoachDebutMatchRequestStatus = createSelector(
  selectSelf,
  getId,
  (state: CoachesState, id: string): RequestStatusType =>
    getExistingCoach(state, id)?.getDebutMatchRequestStatus
);

export const selectCoachDebutMatch = createSelector(
  selectSelf,
  getId,
  (state: CoachesState, id: string): ICoachDebutMatchResponse | undefined | null =>
    getExistingCoach(state, id)?.debutMatch
);

export const selectGetCoachSeasonsRequestStatus = createSelector(
  selectSelf,
  getId,
  (state: CoachesState, id: string): RequestStatusType =>
    getExistingCoach(state, id)?.getSeasonsRequestStatus
);

export const selectCoachSeasons = createSelector(
  selectSelf,
  getId,
  (state: CoachesState, id: string): ICoachSeasonModel[] | undefined | null =>
    getExistingCoach(state, id)?.seasons
);

export const selectGetCoachSeasonRequestStatus = createSelector(
  [selectSelf, getId, getSeasonId],
  (state: CoachesState, id: string, seasonId: string): RequestStatusType =>
    getExistingSeason(state, id, seasonId)?.getSeasonRequestStatus
);

export const selectCoachSeason = createSelector(
  [selectSelf, getId, getSeasonId],
  (state: CoachesState, id: string, seasonId: string): ICoachSeasonModel | undefined | null =>
    getExistingSeason(state, id, seasonId)
);

export const selectGetCoachMatchesInSeasonRequestStatus = createSelector(
  [selectSelf, getId, getSeasonId],
  (state: CoachesState, id: string, seasonId: string): RequestStatusType =>
    getExistingSeason(state, id, seasonId)?.getMatchesRequestStatus
);

export const selectCoachMatchesInSeason = createSelector(
  [selectSelf, getId, getSeasonId],
  (state: CoachesState, id: string, seasonId: string): ICoachMatchModel[] | undefined | null =>
    getExistingSeason(state, id, seasonId)?.matches
);
