import React, { memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { colorArray, colors } from 'consts/colors';

import styles from './Card.module.scss';
import CardFooter from './CardFooter';
import CardTitle from './CardTitle';

const propTypes = {
  // TODO: Vorbert -> (zajmie więcej czasu)
  // TODO: Vorbert -> to o czym gadaliśmy: title/header, footer, footer isSticky, isHoverable etc., footerOnHover, collapsible itp. np roundedBorder czy coś takiego :D co tam przyjdzie Ci do głowy
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  hoverable: PropTypes.bool,
  hoverableCursor: PropTypes.bool,
  onHoverChange: PropTypes.func,
  autoHeight: PropTypes.bool,
  autoWidth: PropTypes.bool,
  alignCenter: PropTypes.bool,
  renderFooter: PropTypes.func,
  footerBackground: PropTypes.oneOf(colorArray),
  renderTitle: PropTypes.func,
  titleBackground: PropTypes.oneOf(colorArray),
  link: PropTypes.shape({
    to: PropTypes.string.isRequired,
    title: PropTypes.string,
    target: PropTypes.string
  })
};

const Card = ({
  children,
  className,
  hoverable,
  hoverableCursor,
  onHoverChange = () => {},
  autoHeight,
  autoWidth,
  alignCenter,
  renderFooter,
  footerBackground = colors.green,
  renderTitle,
  titleBackground = colors.green,
  link
}) => {
  const [cardIsHovered, setCardIsHovered] = useState(false);

  useEffect(
    () => {
      onHoverChange(cardIsHovered);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cardIsHovered]
  );

  const cardClassName = cx(styles.card, className, {
    [styles.hoverable]: hoverable,
    [styles.hoverable_cursor]: hoverableCursor,
    [styles.auto_height]: autoHeight,
    [styles.auto_width]: autoWidth,
    [styles.card_center]: alignCenter
  });

  const CardContainerTag = link ? Link : 'article';

  const handleMouseEnter = () => setCardIsHovered(true);
  const handleMouseLeave = () => setCardIsHovered(false);

  return (
    <CardContainerTag
      className={cardClassName}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...(link || {})}
    >
      {renderTitle && <CardTitle background={titleBackground}>{renderTitle()}</CardTitle>}
      {children}
      {renderFooter && (
        <CardFooter isVisible={cardIsHovered} background={footerBackground}>
          {renderFooter()}
        </CardFooter>
      )}
    </CardContainerTag>
  );
};

Card.propTypes = propTypes;

export default memo(Card);
