import React, { memo, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store';
import {
  cardPropTypes,
  goalPropTypes,
  personWithIdAndPhotoPropTypesShape,
  playerInSquadPropTypesShape,
  simpleTeamPropTypesShape
} from 'propTypes';
import systemParameterIds from 'consts/systemParameterIds';
import urls from 'consts/urls';
import ArrayUtils from 'utils/array.utils';
import { useIsDesktop } from 'utils/customHooks';
import { getSystemParameterValue } from 'utils/systemParameterProvider';
import Card from 'components/Card';
import Column from 'components/Column';
import DateTimeInfo from 'components/DateTimeInfo';
import ClubActivistLink from 'components/Links/ClubActivistLink';
import CoachLink from 'components/Links/CoachLink';
import FootballAssociationActivistLink from 'components/Links/FootballAssociationActivistLink';
import RefereeLink from 'components/Links/RefereeLink';
import Row from 'components/Row';
import SingleInformation from 'components/SingleInformation';
import SingleInformationMultiline from 'components/SingleInformationMultiline';

import styles from './MatchDetailsCard.module.scss';
import MatchDetailsHalfGoals from './MatchDetailsHalfGoals';
import MatchHalfEvents from './MatchHalfEvents';
import MatchScoreSection from './MatchScoreSection';
import MatchTeamSquad from './MatchTeamSquad';
import TeamEntry from './TeamEntry';

// TODO: Vorbert (zajmie chwilkę) -> ujednolicić jakoś rozmiary czcionek w tym komponenciku

const propTypes = {
  host: simpleTeamPropTypesShape.isRequired,
  guest: simpleTeamPropTypesShape.isRequired,
  hostGoals: PropTypes.number,
  guestGoals: PropTypes.number,
  hostFirstHalfGoals: PropTypes.number,
  guestFirstHalfGoals: PropTypes.number,
  hostSecondHalfGoals: PropTypes.number,
  guestSecondHalfGoals: PropTypes.number,
  date: PropTypes.string,
  comment: PropTypes.string,
  isWalkoverForGuest: PropTypes.bool,
  isWalkoverForHost: PropTypes.bool,
  walkoverCause: PropTypes.string,
  type: PropTypes.string,
  league: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }),
  queue: PropTypes.shape({
    id: PropTypes.string,
    number: PropTypes.number
  }),
  stadium: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string
  }),
  referees: PropTypes.arrayOf(personWithIdAndPhotoPropTypesShape), // TODO: change type to referee with function
  footballAssociationActivists: PropTypes.arrayOf(personWithIdAndPhotoPropTypesShape), // TODO: change type to person with function hostCoaches: PropTypes.arrayOf(personWithIdAndPhotoPropTypesShape), // TODO: change type to coach with coachType
  hostCoaches: PropTypes.arrayOf(personWithIdAndPhotoPropTypesShape), // TODO: change type to coach with coachType
  guestCoaches: PropTypes.arrayOf(personWithIdAndPhotoPropTypesShape), // TODO: change type to coach with coachType
  hostClubActivists: PropTypes.arrayOf(personWithIdAndPhotoPropTypesShape), // TODO: change type to person with function
  guestClubActivists: PropTypes.arrayOf(personWithIdAndPhotoPropTypesShape), // TODO: change type to person with function
  firstHalfGoals: PropTypes.arrayOf(
    PropTypes.shape({
      eventTypeId: PropTypes.string,
      teamId: PropTypes.string,
      player: personWithIdAndPhotoPropTypesShape,
      ...goalPropTypes
    })
  ),
  secondHalfGoals: PropTypes.arrayOf(
    PropTypes.shape({
      eventTypeId: PropTypes.string,
      teamId: PropTypes.string,
      player: personWithIdAndPhotoPropTypesShape,
      ...goalPropTypes
    })
  ),
  firstHalfCards: PropTypes.arrayOf(
    PropTypes.shape({
      eventTypeId: PropTypes.string,
      teamId: PropTypes.string,
      player: personWithIdAndPhotoPropTypesShape,
      ...cardPropTypes
    })
  ),
  secondHalfCards: PropTypes.arrayOf(
    PropTypes.shape({
      eventTypeId: PropTypes.string,
      teamId: PropTypes.string,
      player: personWithIdAndPhotoPropTypesShape,
      ...cardPropTypes
    })
  ),
  firstHalfChanges: PropTypes.arrayOf(
    PropTypes.shape({
      eventTypeId: PropTypes.string,
      teamId: PropTypes.string,
      minute: PropTypes.number,
      goingDownPlayer: personWithIdAndPhotoPropTypesShape,
      enteringPlayer: personWithIdAndPhotoPropTypesShape
    })
  ),
  secondHalfChanges: PropTypes.arrayOf(
    PropTypes.shape({
      eventTypeId: PropTypes.string,
      teamId: PropTypes.string,
      minute: PropTypes.number,
      goingDownPlayer: personWithIdAndPhotoPropTypesShape,
      enteringPlayer: personWithIdAndPhotoPropTypesShape
    })
  ),
  hostFirstSquad: PropTypes.arrayOf(playerInSquadPropTypesShape),
  hostReservedSquad: PropTypes.arrayOf(playerInSquadPropTypesShape),
  guestFirstSquad: PropTypes.arrayOf(playerInSquadPropTypesShape),
  guestReservedSquad: PropTypes.arrayOf(playerInSquadPropTypesShape)
};

const MatchDetailsCard = ({
  host,
  guest,
  hostGoals,
  guestGoals,
  hostFirstHalfGoals,
  guestFirstHalfGoals,
  hostSecondHalfGoals,
  guestSecondHalfGoals,
  date,
  comment,
  isWalkoverForGuest,
  isWalkoverForHost,
  walkoverCause,
  type,
  league,
  queue,
  stadium,
  referees,
  footballAssociationActivists,
  hostCoaches,
  guestCoaches,
  hostClubActivists,
  guestClubActivists,
  firstHalfGoals,
  secondHalfGoals,
  firstHalfCards,
  secondHalfCards,
  firstHalfChanges,
  secondHalfChanges,
  hostFirstSquad,
  hostReservedSquad,
  guestFirstSquad,
  guestReservedSquad
}) => {
  const { t } = useTranslation();

  const [isTwoLineVersion, setIsTwoLineVersion] = useState(false);

  const isDesktop = useIsDesktop();

  if (isDesktop && isTwoLineVersion) {
    setIsTwoLineVersion(false);
  } else if (!isDesktop && !isTwoLineVersion) {
    setIsTwoLineVersion(true);
  }

  const hostWon = hostGoals > guestGoals;
  const guestWon = guestGoals > hostGoals;

  const currentSeasonId = useAppSelector(state =>
    getSystemParameterValue(state, systemParameterIds.currentSeasonId)
  );

  const allEvents = [
    ...firstHalfGoals,
    ...secondHalfGoals,
    ...firstHalfCards,
    ...secondHalfCards,
    ...firstHalfChanges,
    ...secondHalfChanges
  ];

  return (
    <Card renderTitle={() => `${host.name} - ${guest.name}`} className={styles.details_card}>
      <Column className={styles.details_container}>
        {date && (
          <Row justifyContent="center" alignItems="center">
            <DateTimeInfo date={date} withDayName shouldWrapDayNameToNextLine />
          </Row>
        )}

        <Row className={styles.details_score_row} justifyContent="center" alignItems="center">
          <TeamEntry {...host} />
          <MatchScoreSection
            hostGoals={hostGoals}
            guestGoals={guestGoals}
            hostWon={hostWon}
            guestWon={guestWon}
            hostFirstHalfGoals={hostFirstHalfGoals}
            guestFirstHalfGoals={guestFirstHalfGoals}
            isWalkover={isWalkoverForGuest || isWalkoverForHost}
          />
          <TeamEntry {...guest} />
        </Row>

        <Row justifyContent="center" alignItems="center">
          <Column justifyContent="center" alignItems="center">
            {type && <SingleInformation className={styles.details_info} value={type} />}

            {league && (
              <SingleInformation
                value={
                  <Link
                    to={urls.competitionsDetails(league.id, currentSeasonId)}
                    title={t('GoToTheLeagueSubpage')}
                  >
                    <span className={styles.details_info}>{league.name}</span>
                  </Link>
                }
              />
            )}

            {queue && (
              <SingleInformation
                title={t('Queue')}
                value={
                  <Link to={urls.queueDetails(queue.id)} title={t('GoToTheQueueSubpage')}>
                    <span className={styles.details_info}>{queue.number}</span>
                  </Link>
                }
              />
            )}

            {stadium && (
              <SingleInformationMultiline
                title={t('Stadium')}
                value={
                  <Link to={urls.stadiumDetails(stadium.id)} title={t('GoToTheStadiumSubpage')}>
                    <span className={styles.details_info}>
                      {stadium.name} {stadium.address}
                    </span>
                  </Link>
                }
              />
            )}

            {comment && <SingleInformationMultiline title={t('Comment')} value={comment} />}

            {walkoverCause && (
              <SingleInformationMultiline title={t('WalkoverCause')} value={walkoverCause} />
            )}
          </Column>
        </Row>
      </Column>

      <MatchDetailsHalfGoals
        title={t('FirstHalf')}
        hostGoals={hostFirstHalfGoals}
        guestGoals={guestFirstHalfGoals}
      />
      <MatchHalfEvents
        goals={firstHalfGoals}
        cards={firstHalfCards}
        changes={firstHalfChanges}
        guestId={guest.id}
        isTwoLineVersion={isTwoLineVersion}
      />

      <MatchDetailsHalfGoals
        title={t('SecondHalf')}
        hostGoals={hostSecondHalfGoals}
        guestGoals={guestSecondHalfGoals}
      />
      <MatchHalfEvents
        goals={secondHalfGoals}
        cards={secondHalfCards}
        changes={secondHalfChanges}
        guestId={guest.id}
        isTwoLineVersion={isTwoLineVersion}
      />

      {/* TODO: maybe extract referees, coaches, people to separate components, like squads */}

      {ArrayUtils.isNotEmpty(referees) && (
        <Row className={styles.details_row}>
          <h3 className={styles.details_row_title}>{t('Referees')}</h3>
        </Row>
      )}

      {ArrayUtils.isNotEmpty(referees) && (
        <Row className={styles.details_row}>
          <Column className={styles.details_row_column}>
            {referees.map(referee => (
              <SingleInformationMultiline
                key={referee.id}
                title={referee.matchFunctionName}
                value={<RefereeLink {...referee} />}
              />
            ))}
          </Column>
        </Row>
      )}

      {ArrayUtils.isNotEmpty(footballAssociationActivists) && (
        <Row className={styles.details_row}>
          <h3 className={styles.details_row_title}>{t('FootballAssociationActivists')}</h3>
        </Row>
      )}

      {ArrayUtils.isNotEmpty(footballAssociationActivists) && (
        <Row className={styles.details_row}>
          <Column className={styles.details_row_column}>
            {footballAssociationActivists.map(footballAssociationActivist => (
              <SingleInformationMultiline
                key={footballAssociationActivist.id}
                title={footballAssociationActivist.matchFunctionName}
                value={<FootballAssociationActivistLink {...footballAssociationActivist} />}
              />
            ))}
          </Column>
        </Row>
      )}

      {(ArrayUtils.isNotEmpty(hostCoaches) || ArrayUtils.isNotEmpty(guestCoaches)) && (
        <Row className={styles.details_row}>
          <h3 className={styles.details_row_title}>{t('Coaches')}</h3>
        </Row>
      )}

      {(ArrayUtils.isNotEmpty(hostCoaches) || ArrayUtils.isNotEmpty(guestCoaches)) && (
        <Row className={styles.details_row}>
          <Column className={styles.details_row_column}>
            {hostCoaches?.map(coach => (
              <SingleInformationMultiline
                key={coach.id}
                title={coach.coachTypeName}
                value={<CoachLink {...coach} />}
              />
            ))}
          </Column>

          <Column className={styles.details_row_column}>
            {guestCoaches?.map(coach => (
              <SingleInformationMultiline
                key={coach.id}
                title={coach.coachTypeName}
                value={<CoachLink {...coach} />}
              />
            ))}
          </Column>
        </Row>
      )}

      {(ArrayUtils.isNotEmpty(hostFirstSquad) || ArrayUtils.isNotEmpty(guestFirstSquad)) && (
        <Row className={styles.details_row}>
          <h3 className={styles.details_row_title}>{t('FirstSquad')}</h3>
        </Row>
      )}

      {(ArrayUtils.isNotEmpty(hostFirstSquad) || ArrayUtils.isNotEmpty(guestFirstSquad)) && (
        <Row className={styles.details_row}>
          {ArrayUtils.isNotEmpty(hostFirstSquad) && (
            <MatchTeamSquad players={hostFirstSquad} events={allEvents} />
          )}
          {ArrayUtils.isNotEmpty(guestFirstSquad) && (
            <MatchTeamSquad players={guestFirstSquad} events={allEvents} />
          )}
        </Row>
      )}

      {(ArrayUtils.isNotEmpty(hostReservedSquad) || ArrayUtils.isNotEmpty(guestReservedSquad)) && (
        <Row className={styles.details_row}>
          <h3 className={styles.details_row_title}>{t('ReservedSquad')}</h3>
        </Row>
      )}

      {(ArrayUtils.isNotEmpty(hostReservedSquad) || ArrayUtils.isNotEmpty(guestReservedSquad)) && (
        <Row className={styles.details_row}>
          {ArrayUtils.isNotEmpty(hostReservedSquad) && (
            <MatchTeamSquad players={hostReservedSquad} events={allEvents} />
          )}
          {ArrayUtils.isNotEmpty(guestReservedSquad) && (
            <MatchTeamSquad players={guestReservedSquad} events={allEvents} />
          )}
        </Row>
      )}

      {(ArrayUtils.isNotEmpty(hostClubActivists) || ArrayUtils.isNotEmpty(guestClubActivists)) && (
        <Row className={styles.details_row}>
          <h3 className={styles.details_row_title}>{t('FunctionalPeople')}</h3>
        </Row>
      )}

      {(ArrayUtils.isNotEmpty(hostClubActivists) || ArrayUtils.isNotEmpty(guestClubActivists)) && (
        <Row className={styles.details_row}>
          <Column className={styles.details_row_column}>
            {hostClubActivists?.map(clubActivist => (
              <SingleInformationMultiline
                key={clubActivist.id}
                title={clubActivist.matchFunctionName}
                value={<ClubActivistLink {...clubActivist} />}
              />
            ))}
          </Column>

          <Column className={styles.details_row_column}>
            {guestClubActivists?.map(clubActivist => (
              <SingleInformationMultiline
                key={clubActivist.id}
                title={clubActivist.matchFunctionName}
                value={<ClubActivistLink {...clubActivist} />}
              />
            ))}
          </Column>
        </Row>
      )}
    </Card>
  );
};

MatchDetailsCard.propTypes = propTypes;

export default memo(MatchDetailsCard);
