import React, { memo } from 'react';
import cx from 'classnames';

import matchResultTypes from 'consts/matchResultTypes';
import { getMatchesCountDescription, getMatchStatusName } from 'utils/utils';

import styles from './MatchStatus.module.scss';
import Tooltip from './Tooltip';

type Props = { status: string; withTooltip?: boolean };

const MatchStatus = ({ status, withTooltip }: Props) => {
  const matchStatusName = getMatchStatusName(status);

  const matchStatusClassName = cx(styles.match, {
    [styles.green]: status === matchResultTypes.win,
    [styles.red]: status === matchResultTypes.loss,
    [styles.blue]: status === matchResultTypes.draw,
    [styles.grey]: status === matchResultTypes.next
  });

  let itemToRender: React.ReactNode;

  if (matchStatusName) {
    itemToRender = (
      <div className={matchStatusClassName}>
        {status !== matchResultTypes.next ? matchStatusName.charAt(0) : '?'}
      </div>
    );

    if (withTooltip) {
      const matchesCountDescription = getMatchesCountDescription(status);

      itemToRender = <Tooltip html={matchesCountDescription}>{itemToRender}</Tooltip>;
    }
  } else {
    itemToRender = '';
  }

  return itemToRender;
};

export default memo(MatchStatus);
