import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useIsCurrentlyOnUrl } from 'utils/customHooks';

import styles from './DropdownItem.module.scss';

const propTypes = {
  address: PropTypes.string,
  label: PropTypes.string.isRequired,
  className: PropTypes.string
};

const SubmenuLink = ({ address, label, className }) => {
  const isActiveUrl = useIsCurrentlyOnUrl(address);
  const linkClassName = cx(className, {
    [styles.item_active]: isActiveUrl
  });

  return (
    <Link to={address} className={linkClassName}>
      {label}
    </Link>
  );
};

SubmenuLink.propTypes = propTypes;

export default memo(SubmenuLink);
