import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ELanguageDisplayNames } from 'consts/language';
import { useCurrentLanguage, useImmediateInterval } from 'utils/customHooks';
import { getDifferenceDurationTime } from 'utils/dateFormat';
import { getCountdownText } from 'utils/timeTranslations';

import styles from './MatchCountDown.module.scss';

const propTypes = {
  destinationTime: PropTypes.string.isRequired
};

const MatchCountDown = ({ destinationTime }) => {
  const { t } = useTranslation();
  // TODO: Vorbert -> popracować nad inicjalnymi wartościami xD
  const [days, setDays] = useState(1);
  const [hours, setHours] = useState(1);
  const [minutes, setMinutes] = useState(20);
  const [seconds, setSeconds] = useState(69);
  const [isCounting, setIsCounting] = useState(true);
  const currentLanguage = useCurrentLanguage();

  // TODO: Vorbert -> po zmianach przetestować to dla różnych czasów (oraz mniejszych interwałów, by nie trzeba było tyle czekać xD)
  useImmediateInterval(
    () => {
      const differenceTime = getDifferenceDurationTime(new Date(), destinationTime);

      if (isCounting) {
        // TODO: Vorbert -> zmienić sposób zapisywania tych danych, np.: przechowując je w jednym obiekcie, tudzież nie korzystając z samego state (useRefState może być dobrym pomysłem)
        setDays(Math.floor(differenceTime.asDays()));
        setHours(differenceTime.hours());
        setMinutes(differenceTime.minutes());
        setSeconds(differenceTime.seconds());
        // TODO: Vorbert -> ogarnąć, by setIsCounting nie wywoływal się za każdym razem, gdy interval zrobi pętle
        setIsCounting(differenceTime.asMilliseconds() >= 0);
      }
    },
    1000,
    !isCounting
    // TODO: czy tak jak było: [1000, !isCounting] ?
  );

  return (
    <div className={styles.countdown}>
      {isCounting
        ? getCountdownText(
            days,
            hours,
            minutes,
            seconds,
            currentLanguage.displayName === ELanguageDisplayNames.Polski
          )
        : `(${t('CurrentlyInProgress')})`}
    </div>
  );
};

MatchCountDown.propTypes = propTypes;

export default memo(MatchCountDown);
