import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './MobileSubmenu.module.scss';
import MobileSubmenuLink from './MobileSubmenuLink';

const propTypes = {
  subLinksData: PropTypes.arrayOf(PropTypes.object).isRequired
};

const MobileSubmenu = ({ subLinksData }) => {
  const { t } = useTranslation();

  const submenuLinks = subLinksData.map((linkData, index) => (
    <MobileSubmenuLink
      key={index}
      address={linkData.address}
      label={linkData.shouldNotTranslateLabel ? linkData.label : t(linkData.label)}
      onClick={linkData.onClick}
    />
  ));

  return <ul className={styles.subLink_container}>{submenuLinks}</ul>;
};

MobileSubmenu.propTypes = propTypes;

export default memo(MobileSubmenu);
