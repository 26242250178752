import React, { memo, useMemo } from 'react';
import cx from 'classnames';

import { icons } from 'consts/icons';
import { toTimeFormat } from 'utils/dateFormat';

import styles from './TimeInfo.module.scss';

type Props = {
  date?: Date;
  withoutIcon?: boolean;
};

const TimeInfo = ({ date, withoutIcon }: Props) => {
  const timeTomeDisplay = useMemo(() => toTimeFormat(date), [date]);
  const containerClassName = cx(styles.container);
  const iconClassName = cx(icons.clock, styles.margin_right);

  return (
    date && (
      <div className={containerClassName}>
        {!withoutIcon && <i className={iconClassName} />}
        <span>{timeTomeDisplay}</span>
      </div>
    )
  );
};

export default memo(TimeInfo);
