import React, { memo, ReactNode, useMemo } from 'react';
import cx from 'classnames';

import Skeleton from 'components/Skeleton';

import Card from '../Card';
import Column from '../Column';
import UserCardAvatar from '../UserCardAvatar';
// TODO: ujednolicić style bo jest i module.scss i styles z styled-components
import styles from './PersonDetailsCard.module.scss';
import { StyledBadge } from './PersonDetailsCard.styles';

type Props = {
  firstName?: string | null;
  lastName?: string | null;
  photo?: string | null;
  isPhotoLoading?: boolean;
  isDetailsLoading?: boolean;
  badgeLabel: string;
  children: ReactNode;
};

const PersonDetailsCard = ({
  photo,
  isPhotoLoading,
  isDetailsLoading,
  firstName,
  lastName,
  badgeLabel,
  children
}: Props) => {
  const fullName = useMemo(() => `${firstName} ${lastName}`, [firstName, lastName]);

  // TODO: inny kolor w zależności od tego kim dana osoba jest - trener, zawodnik itp.
  const cardHeaderLabelClassName = cx(styles.card_head, styles.card_head_label);

  return (
    <Card className={styles.card}>
      <div className={cardHeaderLabelClassName}>
        <StyledBadge>{badgeLabel}</StyledBadge>
      </div>

      <div className={styles.card_head}>
        {!isDetailsLoading ? <StyledBadge>{fullName}</StyledBadge> : <Skeleton />}
      </div>

      {/* TODO: spróbować zrobić dwie kolumny żeby komponent był szerszy a niższy,
      tak jak jest teraz może być na mobilnych urządzeniach? - zastanowić się
      ujednolicić w całej aplikacji, TeamCard itp.
      <Row>
        <Column className={styles.card_body}>
          <UserCardAvatar src={photo} />
        </Column>
        <Column className={styles.card_body}>
          <SingleInformationMultiline title={t('NameAndSurname')} value={fullName} />
          {children}
        </Column>
      </Row> */}
      <Column className={styles.card_body}>
        <UserCardAvatar src={photo} isLoading={isPhotoLoading} />

        {children}
      </Column>
    </Card>
  );
};

export default memo(PersonDetailsCard);
