const urls = {
  login: '/login',
  register: '/register',
  resetPassword: '/reset-password',
  setNewPassword: (token: string) => `/set-new-password/${token}`,
  myProfile: '/my-profile',
  statistics: '/statistics',
  articles: '/articles',
  articleDetails: (id: string) => `/article/${id}/details`,
  playerDetails: (id: string) => `/player/${id}/details`,
  coachDetails: (id: string) => `/coach/${id}/details`,
  personDetails: (id: string) => `/person/${id}/details`,
  clubActivistDetails: (id: string) => `/clubActivist/${id}/details`,
  boardMemberDetails: (id: string) => `/boardMember/${id}/details`,
  refereeDetails: (id: string) => `/referee/${id}/details`,
  footballAssociationActivistDetails: (id: string) => `/footballAssociationActivist/${id}/details`,
  matchDetails: (id: string) => `/match/${id}/details`,
  stadiumDetails: (id: string) => `/stadium/${id}/details`,

  currentCompetitionsDetails: '/competitions/details',
  currentCompetitionsSchedule: '/competitions/competitions-schedule',
  currentCompetitionsTable: '/competitions/table',

  competitionsDetails: (leagueId: string, seasonId: string) =>
    `/competitions/league/${leagueId}/season/${seasonId}/details`,
  competitionsSchedule: (leagueId: string, seasonId: string) =>
    `/competitions/league/${leagueId}/season/${seasonId}/competitions-schedule`,
  competitionsTable: (leagueId: string, seasonId: string) =>
    `/competitions/league/${leagueId}/season/${seasonId}/table`,

  playerSeasonStatistics: (playerId: string, seasonId: string) =>
    `/player/${playerId}/season/${seasonId}`,

  coachSeasonStatistics: (coachId: string, seasonId: string) =>
    `/coach/${coachId}/season/${seasonId}`,

  refereeSeasonStatistics: (refereeId: string, seasonId: string) =>
    `/referee/${refereeId}/season/${seasonId}`,

  personSeasonStatistics: (personId: string, seasonId: string) =>
    `/person/${personId}/season/${seasonId}`,

  teamSeasonStatistics: (teamId: string, seasonId: string) =>
    `/team/${teamId}/competitions/season/${seasonId}`,

  queueDetails: (id: string) => `/queue/${id}/details`,

  myTeamDetails: '/team/details',
  myTeamPlayers: '/team/players',

  teamDetails: (id: string) => `/team/${id}/details`,
  teamPlayers: (id: string) => `/team/${id}/players`,

  myClubDetails: '/club/details',
  myClubContact: '/club/contact',
  myClubHistory: '/club/history',
  myClubStadiumDescription: '/club/stadium-description',
  myClubBoardMembers: '/club/board-members',
  myClubPartners: '/club/partners',
  myClubSponsors: '/club/sponsors',

  clubDetails: (id: string) => `/club/${id}/details`,
  clubContact: (id: string) => `/club/${id}/contact`,
  clubHistory: (id: string) => `/club/${id}/history`,
  clubStadiumDescription: (id: string) => `/club/${id}/stadium-description`,
  clubBoardMembers: (id: string) => `/club/${id}/board-members`,
  clubPartners: (id: string) => `/club/${id}/partners`,
  clubSponsors: (id: string) => `/club/${id}/sponsors`,

  gallery: '/gallery',
  galleryAlbum: (id: string) => `/gallery/album/${id}`,
  maintance: '/maintance',
  forbiddenPage: '/forbidden'
};

export default urls;
