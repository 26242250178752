import React, { memo } from 'react';
import cx from 'classnames';

import styles from './Loader.module.scss';

type Props = { isVisible?: boolean; className?: string };

// TODO: Vorbert -> przepisać na styled-components
const Loader = ({ isVisible = true, className }: Props) => {
  const loaderClassName = cx(styles.loader, className, {
    [styles.loader_hidden]: !isVisible
  });

  return (
    <div className={loaderClassName}>
      <div className={styles.loader_shadow} />
      <div className={styles.loader_box} />
    </div>
  );
};

export default memo(Loader);
