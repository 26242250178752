import React, { memo, useMemo } from 'react';
import cx from 'classnames';

import { icons } from 'consts/icons';
import { getWeekDayName, toDateFormat } from 'utils/dateFormat';

import styles from './DateInfo.module.scss';
import Tooltip from './Tooltip';

type Props = {
  date?: Date;
  withoutIcon?: boolean;
  withDayName?: boolean;
  shouldWrapDayNameToNextLine?: boolean;
};

const DateInfo = ({ date, withoutIcon, withDayName, shouldWrapDayNameToNextLine }: Props) => {
  const dayNameToDisplay = useMemo(() => getWeekDayName(date), [date]);
  const dateToDisplay = useMemo(() => toDateFormat(date), [date]);
  const containerClassName = cx({
    [styles.container_oneLine]: !shouldWrapDayNameToNextLine,
    [styles.container_twoLines]: shouldWrapDayNameToNextLine
  });
  const iconClassName = cx(icons.calendar, styles.margin_right);

  return date ? (
    <Tooltip html={dayNameToDisplay}>
      <div className={containerClassName}>
        {!withoutIcon ? <i className={iconClassName} /> : ''}
        <span>{dateToDisplay}</span>
        {withDayName ? <div className={styles.dayName}>&nbsp;({dayNameToDisplay})</div> : ''}
      </div>
    </Tooltip>
  ) : (
    ''
  );
};

export default memo(DateInfo);
