import { createSelector } from '@reduxjs/toolkit';
import { RequestStatusType } from 'models/common.models';

import { RootState } from 'store';

import { IRefereeMatchModel, IRefereeModel, IRefereeSeasonModel, RefereesState } from './models';
import {
  IRefereeDebutMatchResponse,
  IRefereeDetailsResponse
} from './models/refereeResponse.models';
import { getExistingReferee, getExistingSeason } from './referee.functions';

const selectSelf = (state: RootState) => state.referees;
const getId = (_state: RootState, id: string) => id;
const getSeasonId = (_state: RootState, _id: string, seasonId: string) => seasonId;

export const selectReferee = createSelector(
  selectSelf,
  getId,
  (state: RefereesState, id: string): IRefereeModel | undefined | null =>
    getExistingReferee(state, id)
);

export const selectGetRefereeDetailsRequestStatus = createSelector(
  selectSelf,
  getId,
  (state: RefereesState, id: string): RequestStatusType =>
    getExistingReferee(state, id)?.getDetailsRequestStatus
);

export const selectRefereeDetails = createSelector(
  selectSelf,
  getId,
  (state: RefereesState, id: string): IRefereeDetailsResponse | undefined | null =>
    getExistingReferee(state, id)?.details
);

export const selectGetRefereePhotoRequestStatus = createSelector(
  selectSelf,
  getId,
  (state: RefereesState, id: string): RequestStatusType =>
    getExistingReferee(state, id)?.getPhotoRequestStatus
);

export const selectRefereePhoto = createSelector(
  selectSelf,
  getId,
  (state: RefereesState, id: string): string | undefined | null =>
    getExistingReferee(state, id)?.photo
);

export const selectGetRefereeDebutMatchRequestStatus = createSelector(
  selectSelf,
  getId,
  (state: RefereesState, id: string): RequestStatusType =>
    getExistingReferee(state, id)?.getDebutMatchRequestStatus
);

export const selectRefereeDebutMatch = createSelector(
  selectSelf,
  getId,
  (state: RefereesState, id: string): IRefereeDebutMatchResponse | undefined | null =>
    getExistingReferee(state, id)?.debutMatch
);

export const selectGetRefereeSeasonsRequestStatus = createSelector(
  selectSelf,
  getId,
  (state: RefereesState, id: string): RequestStatusType =>
    getExistingReferee(state, id)?.getSeasonsRequestStatus
);

export const selectRefereeSeasons = createSelector(
  selectSelf,
  getId,
  (state: RefereesState, id: string): IRefereeSeasonModel[] | undefined | null =>
    getExistingReferee(state, id)?.seasons
);

export const selectGetRefereeSeasonRequestStatus = createSelector(
  [selectSelf, getId, getSeasonId],
  (state: RefereesState, id: string, seasonId: string): RequestStatusType =>
    getExistingSeason(state, id, seasonId)?.getSeasonRequestStatus
);

export const selectRefereeSeason = createSelector(
  [selectSelf, getId, getSeasonId],
  (state: RefereesState, id: string, seasonId: string): IRefereeSeasonModel | undefined | null =>
    getExistingSeason(state, id, seasonId)
);

export const selectGetRefereeMatchesInSeasonRequestStatus = createSelector(
  [selectSelf, getId, getSeasonId],
  (state: RefereesState, id: string, seasonId: string): RequestStatusType =>
    getExistingSeason(state, id, seasonId)?.getMatchesRequestStatus
);

export const selectRefereeMatchesInSeason = createSelector(
  [selectSelf, getId, getSeasonId],
  (state: RefereesState, id: string, seasonId: string): IRefereeMatchModel[] | undefined | null =>
    getExistingSeason(state, id, seasonId)?.matches
);
