import React, { memo } from 'react';

import { ESortDirections } from 'consts/ESortDirections';
import AssistIcon from 'components/Icon/AssistIcon';
import CardRedIcon from 'components/Icon/CardRedIcon';
import CardSecondYellowIcon from 'components/Icon/CardSecondYellowIcon';
import CardYellowIcon from 'components/Icon/CardYellowIcon';
import GoalIcon from 'components/Icon/GoalIcon';
import MinutesIcon from 'components/Icon/MinutesIcon';

import { EColumnIndexes, EColumnNames } from './table.consts';
import TableHeaderCell from './TableHeaderCell';

type Props = {
  sortDirection: ESortDirections | null;
  sortedColumnIndex: number;
  setHoveredColumnIndex: (columnIndex: number | null) => void;
  sortColumn: (columnIndex: number) => void;
};

const TableHeader = ({
  sortDirection,
  sortedColumnIndex,
  setHoveredColumnIndex,
  sortColumn
}: Props) => {
  const handleOnMouseLeave = () => setHoveredColumnIndex(null);

  return (
    <thead onMouseLeave={handleOnMouseLeave}>
      <tr>
        <TableHeaderCell
          columnIndex={EColumnIndexes.fullName}
          columnName={EColumnNames.fullName}
          sortedColumnIndex={sortedColumnIndex}
          sortDirection={sortDirection}
          setHoveredColumnIndex={setHoveredColumnIndex}
          sortColumn={sortColumn}
        />

        <TableHeaderCell
          columnIndex={EColumnIndexes.minutesInAllMatchesCount}
          columnName={EColumnNames.minutesInAllMatchesCount}
          sortedColumnIndex={sortedColumnIndex}
          sortDirection={sortDirection}
          setHoveredColumnIndex={setHoveredColumnIndex}
          sortColumn={sortColumn}
        >
          <MinutesIcon />
        </TableHeaderCell>

        <TableHeaderCell
          columnIndex={EColumnIndexes.goalsInAllMatchesCount}
          columnName={EColumnNames.goalsInAllMatchesCount}
          sortedColumnIndex={sortedColumnIndex}
          sortDirection={sortDirection}
          setHoveredColumnIndex={setHoveredColumnIndex}
          sortColumn={sortColumn}
        >
          <GoalIcon />
        </TableHeaderCell>

        <TableHeaderCell
          columnIndex={EColumnIndexes.ownGoalsInAllMatchesCount}
          columnName={EColumnNames.ownGoalsInAllMatchesCount}
          sortedColumnIndex={sortedColumnIndex}
          sortDirection={sortDirection}
          setHoveredColumnIndex={setHoveredColumnIndex}
          sortColumn={sortColumn}
        >
          <GoalIcon isOwn />
        </TableHeaderCell>

        <TableHeaderCell
          columnIndex={EColumnIndexes.assistsInAllMatchesCount}
          columnName={EColumnNames.assistsInAllMatchesCount}
          sortedColumnIndex={sortedColumnIndex}
          sortDirection={sortDirection}
          setHoveredColumnIndex={setHoveredColumnIndex}
          sortColumn={sortColumn}
        >
          <AssistIcon />
        </TableHeaderCell>

        <TableHeaderCell
          columnIndex={EColumnIndexes.yellowCardsInAllMatchesCount}
          columnName={EColumnNames.yellowCardsInAllMatchesCount}
          sortedColumnIndex={sortedColumnIndex}
          sortDirection={sortDirection}
          setHoveredColumnIndex={setHoveredColumnIndex}
          sortColumn={sortColumn}
        >
          <CardYellowIcon />
        </TableHeaderCell>

        <TableHeaderCell
          columnIndex={EColumnIndexes.secondYellowCardsInAllMatchesCount}
          columnName={EColumnNames.secondYellowCardsInAllMatchesCount}
          sortedColumnIndex={sortedColumnIndex}
          sortDirection={sortDirection}
          setHoveredColumnIndex={setHoveredColumnIndex}
          sortColumn={sortColumn}
        >
          <CardSecondYellowIcon />
        </TableHeaderCell>

        <TableHeaderCell
          columnIndex={EColumnIndexes.redCardsInAllMatchesCount}
          columnName={EColumnNames.redCardsInAllMatchesCount}
          sortedColumnIndex={sortedColumnIndex}
          sortDirection={sortDirection}
          setHoveredColumnIndex={setHoveredColumnIndex}
          sortColumn={sortColumn}
        >
          <CardRedIcon />
        </TableHeaderCell>
      </tr>
    </thead>
  );
};

export default memo(TableHeader);
