import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ArticleGalleryItem.module.scss';

type Props = {
  photo: string;
  onClick: () => void;
};

const ArticleGalleryItem = ({ photo, onClick }: Props) => {
  const { t } = useTranslation();
  return <img className={styles.image} src={photo} alt={t('GalleryPhoto')} onClick={onClick} />;
};

export default memo(ArticleGalleryItem);
