import React, { memo } from 'react';

import StyledWrapper from './StyledWrapper';

type Props = {
  className?: string;
};

const DefaultAvatar = ({ className }: Props) => {
  const accentVariableName = '--position-accent';

  return (
    <StyledWrapper className={className} viewBox="0 0 264 280" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask1" width={200} height={244} x={32} y={36} maskUnits="userSpaceOnUse">
        <path
          fill="#fff"
          d="M156 180.6V199h4a72 72 0 0172 72v9H32v-9a72 72 0 0172-72h4v-18.4A56 56 0 0176.3 136 12 12 0 0166 124v-14a12 12 0 0110-11.8V92a56 56 0 11112 0v6.2a12 12 0 0110 11.8v14c0 6-4.5 11-10.3 11.9a56 56 0 01-31.7 44.7z"
        />
      </mask>
      <g mask="url(#mask1)">
        <path
          fill="#D0C6AC"
          d="M156 180.6V199h4a72 72 0 0172 72v9H32v-9a72 72 0 0172-72h4v-18.4A56 56 0 0176.3 136 12 12 0 0166 124v-14a12 12 0 0110-11.8V92a56 56 0 11112 0v6.2a12 12 0 0110 11.8v14c0 6-4.5 11-10.3 11.9a56 56 0 01-31.7 44.7z"
        />
        <path fill="#EDB98A" d="M264 36H0v244h264V36z" />
        <path
          fillOpacity={0.1}
          fillRule="evenodd"
          d="M188 115v23a56 56 0 11-112 0v-23 15a56 56 0 10112 0v-15z"
          clipRule="evenodd"
        />
      </g>
      <mask id="mask2" width={200} height={98} x={32} y={183} maskUnits="userSpaceOnUse">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M108 183c-18 2-31.7 7.6-32 21.6A72 72 0 0032 271v9h200v-9a72 72 0 00-44-66.4c-.3-14-14-19.5-32-21.5V202a24 24 0 11-48 0v-19z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#mask2)">
        <path
          fill="#B7C1DB"
          fillRule="evenodd"
          d="M108 183c-18 2-31.7 7.6-32 21.6A72 72 0 0032 271v9h200v-9a72 72 0 00-44-66.4c-.3-14-14-19.5-32-21.5V202a24 24 0 11-48 0v-19z"
          clipRule="evenodd"
        />
        <path fill={`var(${accentVariableName}, #0dc372)`} d="M264 170H0v110h264V170z" />
        <path
          fill="#F4F4F4"
          fillRule="evenodd"
          d="M102 231.7V280h-7v-51.9a64.5 64.5 0 007 3.6zm67-3.5v40.3a3.5 3.5 0 11-7 0v-36.8a64.5 64.5 0 007-3.5z"
          clipRule="evenodd"
        />
        <path
          fillOpacity={0.16}
          fillRule="evenodd"
          d="M91 182.7c-15 2.9-25.5 8.5-25.5 19.6 0 19.7 33 35.7 66.5 35.7s66.5-16 66.5-35.7c0-11-10.4-16.7-25.5-19.6 9.1 3.4 15 9 15 18.4 0 20.4-27.8 36.9-56 36.9s-56-16.5-56-37c0-9.3 5.9-15 15-18.3z"
          clipRule="evenodd"
        />
      </g>
      <path
        fillOpacity={0.6}
        d="M118 150c0 5.4 6.2 9 14 9s14-3.6 14-9c0-1.1-1-2-2-2-1.3 0-1.9 1-2 2-1.2 3-4.3 4.7-10 5-5.7-.3-8.8-2-10-5-.1-1-.7-2-2-2-1 0-2 .9-2 2z"
      />
      <path
        fillOpacity={0.16}
        fillRule="evenodd"
        d="M120 130c0 4.4 5.4 8 12 8s12-3.6 12-8"
        clipRule="evenodd"
      />
      <path
        fillOpacity={0.6}
        d="M106 118a6 6 0 100-12 6 6 0 000 12zm52 0a6 6 0 100-12 6 6 0 000 12zM91.6 99.2c4-5.6 14.7-8.6 24-6.4a2 2 0 10.9-3.9c-10.8-2.6-23.2 1-28.1 8a2 2 0 103.2 2.3zm80.8 0c-4-5.6-14.7-8.6-24-6.4a2 2 0 01-.9-3.9c10.8-2.6 23.2 1 28.1 8a2 2 0 01-3.2 2.3z"
      />
      <mask id="mask3" width={264} height={280} x={0} y={0} maskUnits="userSpaceOnUse">
        <path fill="#fff" d="M264 0H0v280h264V0z" />
      </mask>
      <g mask="url(#mask3)">
        <mask id="mask4" width={118} height={82} x={72} y={19} maskUnits="userSpaceOnUse">
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M185.8 74c1.8 1 3.2 10.8 3.2 14.6 0 2.4.1 11.3-2.3 10.5-.8-.2-2-5-2.1-7.7a53.5 53.5 0 00-4.2-16.5c-.5-1-2-2.7-1.4-3.6.8-1 1.7-.4 2.7 0 1.1.5 4 2.5 4.1 2.6zm.8-3c-.7 1-5.8-1.8-8-3-17.8-9.8-21.2-13-49.4-12.5a85.2 85.2 0 00-48.4 14.9c-.8 1-1.7 3.4-2.5 10.4s.2 19.5-2.7 19.5c-2.4 0-3-23.8-1.9-33.3.2-1.4.8-4 .3-5.3-.3-1.1-2.3-1.1-2-3 .4-2.2 2.6-1.1 3.5-2.1 1.8-2-1-2.5-1.4-4.2-.7-2.5 1.3-3.2 3-4 2-.9 1.7 0 3.3-1.8-2-1.6-2.9-3.7 0-4.8 1.3-.5 4.5.1 5.5-.6.3-.2.4-1.3.5-1.5 1.6-2.2 1.2-3.8 1-6.3-.1-2 0-4 2.3-4.1 1.7-.2 3 1.1 4.5 1.5 1.6.5 1 .8 2.2 0 2.3-1.6.7-4.6 2.5-6.4 2.4-2.4 4 1.2 6 1.4 4.2.6 2.8-3.7 5.3-5.2 3-2 3.3 2.2 5.2 2.3 1.6.2 4.5-3 6-3.5 4.1-1.8 6.2 3.1 10.2 2.5 2.8-.5 3.6-.8 6.2.8s3.2.5 6 .4c2 0 3.3 1.7 5 1.9.8 0 1.6-.8 2.5-.7 3.3.2 3.5 4.3 6 5.5 2.4 1 5.2-.4 7.5-.7 5.7-.7 1.8 4.6 2 7.8 2.2.5 4.5-1.5 6.7-1.2 3.5.6 2.2 4.3 1.4 6.7 2.4 0 8.7-2.7 10.3.7.8 1.8-1 4.2-1 6 0 2.5 1.8 4.6 2.5 7 .9 3.3 2.2 12 0 14.9z"
            clipRule="evenodd"
          />
        </mask>
        <g mask="url(#mask4)">
          <path
            fill="#2E3257"
            fillRule="evenodd"
            d="M185.8 74c1.8 1 3.2 10.8 3.2 14.6 0 2.4.1 11.3-2.3 10.5-.8-.2-2-5-2.1-7.7a53.5 53.5 0 00-4.2-16.5c-.5-1-2-2.7-1.4-3.6.8-1 1.7-.4 2.7 0 1.1.5 4 2.5 4.1 2.6zm.8-3c-.7 1-5.8-1.8-8-3-17.8-9.8-21.2-13-49.4-12.5a85.2 85.2 0 00-48.4 14.9c-.8 1-1.7 3.4-2.5 10.4s.2 19.5-2.7 19.5c-2.4 0-3-23.8-1.9-33.3.2-1.4.8-4 .3-5.3-.3-1.1-2.3-1.1-2-3 .4-2.2 2.6-1.1 3.5-2.1 1.8-2-1-2.5-1.4-4.2-.7-2.5 1.3-3.2 3-4 2-.9 1.7 0 3.3-1.8-2-1.6-2.9-3.7 0-4.8 1.3-.5 4.5.1 5.5-.6.3-.2.4-1.3.5-1.5 1.6-2.2 1.2-3.8 1-6.3-.1-2 0-4 2.3-4.1 1.7-.2 3 1.1 4.5 1.5 1.6.5 1 .8 2.2 0 2.3-1.6.7-4.6 2.5-6.4 2.4-2.4 4 1.2 6 1.4 4.2.6 2.8-3.7 5.3-5.2 3-2 3.3 2.2 5.2 2.3 1.6.2 4.5-3 6-3.5 4.1-1.8 6.2 3.1 10.2 2.5 2.8-.5 3.6-.8 6.2.8s3.2.5 6 .4c2 0 3.3 1.7 5 1.9.8 0 1.6-.8 2.5-.7 3.3.2 3.5 4.3 6 5.5 2.4 1 5.2-.4 7.5-.7 5.7-.7 1.8 4.6 2 7.8 2.2.5 4.5-1.5 6.7-1.2 3.5.6 2.2 4.3 1.4 6.7 2.4 0 8.7-2.7 10.3.7.8 1.8-1 4.2-1 6 0 2.5 1.8 4.6 2.5 7 .9 3.3 2.2 12 0 14.9z"
            clipRule="evenodd"
          />
          <path fill="#B58143" d="M264 0H0v280h264V0z" />
        </g>
      </g>
    </StyledWrapper>
  );
};

export default memo(DefaultAvatar);
