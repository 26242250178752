import React from 'react';
import { useTranslation } from 'react-i18next';
import defaultAvatar from 'assets/images/BoardMember_default_avatar.svg';

import { boardMemberDetailsPropTypes } from 'propTypes';
import { icons } from 'consts/icons';
import urls from 'consts/urls';
import IconButton from 'components/IconButton';
import ClubLink from 'components/Links/ClubLink';
import PersonDetailsCard from 'components/PersonDetailsCard';
import SingleInformationMultiline from 'components/SingleInformationMultiline';

import styles from './BoardMemberDetailsCard.module.scss';

const propTypes = {
  ...boardMemberDetailsPropTypes
};

const BoardMemberDetailsCard = ({ photo, firstName, lastName, stand, currentClub }) => {
  const { t } = useTranslation();

  return (
    <PersonDetailsCard
      photo={photo || defaultAvatar}
      firstName={firstName}
      lastName={lastName}
      badgeLabel={stand}
    >
      <SingleInformationMultiline title={t('Stand')} value={stand} />
      <SingleInformationMultiline
        title={t('Club')}
        value={<ClubLink {...currentClub} withoutEmphasisMyClub />}
      />
      <IconButton
        className={styles.card_button}
        icon={icons.chevronRight}
        title={`${t('VisitTheClubSite')}: ${currentClub.name}`}
        to={urls.clubDetails(currentClub.id)}
      >
        {t('VisitTheClubSite')}
      </IconButton>
    </PersonDetailsCard>
  );
};

BoardMemberDetailsCard.propTypes = propTypes;

export default BoardMemberDetailsCard;
