import React, { memo } from 'react';

import { EIcons } from 'consts/icons';

import StyledIcon from './StyledIcon';

type Props = {
  rotated?: boolean;
  disabled?: boolean;
  onClick: () => void;
};

const ArrowButton = ({ rotated, disabled, onClick }: Props) => (
  <StyledIcon icon={EIcons.chevronRight} rotated={rotated} disabled={disabled} onClick={onClick} />
);

export default memo(ArrowButton);
