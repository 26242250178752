import React, { memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { HistoryRouter } from 'redux-first-history/rr6';

import { history } from 'store';
import urls from 'consts/urls';
import ArticleDetailsPageContainer from 'modules/article/ArticleDetailsPageContainer';
import ArticlesPageContainer from 'modules/articles/ArticlesPageContainer';
import SetNewPasswordPageContainer from 'modules/auth//SetNewPassword/SetNewPasswordPageContainer';
import AuthPageContainer from 'modules/auth/AuthPageContainer';
import MyProfilePage from 'modules/auth/MyProfile/MyProfilePage';
import ResetPasswordPageContainer from 'modules/auth/ResetPassword/ResetPasswordPageContainer';
import BoardMemberDetailsPageContainer from 'modules/board-member/BoardMemberDetailsPageContainer';
import BoardMembersPageContainer from 'modules/club/board-members/BoardMembersPageContainer';
import ContactPageContainer from 'modules/club/contact/ContactPageContainer';
import ClubDetailsPageContainer from 'modules/club/details/ClubDetailsPageContainer';
import MyClubDetailsPageContainer from 'modules/club/details/MyClubDetailsPageContainer';
import HistoryPageContainer from 'modules/club/history/HistoryPageContainer';
import PartnersPageContainer from 'modules/club/partners-and-sponsors/PartnersPageContainer';
import SponsorsPageContainer from 'modules/club/partners-and-sponsors/SponsorsPageContainer';
import StadiumDescriptionPageContainer from 'modules/club/stadium-description/StadiumDescriptionPageContainer';
import CoachDetailsPageContainer from 'modules/coach/CoachDetailsPageContainer';
import CoachSeasonStatisticsPageContainer from 'modules/coach/CoachSeasonStatisticsPageContainer';
import CompetitionsSchedulePageContainer from 'modules/competitions/competitions-schedule/CompetitionsSchedulePageContainer';
import CurrentCompetitionsSchedulePageContainer from 'modules/competitions/competitions-schedule/CurrentCompetitionsSchedulePageContainer';
import CompetitionsDetailsPageContainer from 'modules/competitions/details/CompetitionsDetailsPageContainer';
import CurrentCompetitionsDetailsPageContainer from 'modules/competitions/details/CurrentCompetitionsDetailsPageContainer';
import CurrentCompetitionsTablePageContainer from 'modules/competitions/table/CurrentCompetitionsTablePageContainer';
import TablePageContainer from 'modules/competitions/table/TablePageContainer';
import ErrorPage from 'modules/error-page/ErrorPage';
import ErrorTestPage from 'modules/error-test-page/ErrorTestPage';
import GalleryPageContainer from 'modules/gallery/GalleryPageContainer';
import HomePageContainer from 'modules/home/HomePageContainer';
import MaintancePage from 'modules/maintance-page/MaintancePage';
import MatchDetailsPageContainer from 'modules/match/MatchDetailsPageContainer';
import NotFoundPage from 'modules/not-found-page/NotFoundPage';
import PlayerDetailsPageContainer from 'modules/player/PlayerDetailsPageContainer';
import PlayerSeasonStatisticsPageContainer from 'modules/player/PlayerSeasonStatisticsPageContainer';
import RefereeDetailsPageContainer from 'modules/referee/RefereeDetailsPageContainer';
import StadiumPage from 'modules/stadiums/StadiumPage';
import StatisticsPage from 'modules/statistics/StatisticsPage';
import MyTeamDetailsPageContainer from 'modules/team/MyTeamDetailsPageContainer';
import MyTeamPlayersInCurrentSeasonPageContainer from 'modules/team/players/MyTeamPlayersInCurrentSeasonPageContainer';
import TeamPlayersInCurrentSeasonPageContainer from 'modules/team/players/TeamPlayersInCurrentSeasonPageContainer';
import TeamDetailsPageContainer from 'modules/team/TeamDetailsPageContainer';
import TeamSeasonsStatisticsPageContainer from 'modules/team/TeamSeasonsStatisticsPageContainer';
import TestPage from 'modules/test-page/TestPage';
import DefaultLayoutRoute from 'components/DefaultLayoutRoute';

// TODO: czy teraz już nie potrzeba tego?
// import { BrowserRouter } from 'react-router-dom';
// <BrowserRouter>

const Router = () => {
  return (
    <div className="App">
      <HistoryRouter history={history}>
        <Routes>
          <Route path={urls.register} element={<AuthPageContainer />} />
          <Route path={urls.login} element={<AuthPageContainer />} />
          <Route path={urls.resetPassword} element={<ResetPasswordPageContainer />} />
          <Route path={urls.setNewPassword(':token')} element={<SetNewPasswordPageContainer />} />

          <Route element={<DefaultLayoutRoute />}>
            <Route path="/" element={<HomePageContainer />} />
            <Route path={urls.myProfile} element={<MyProfilePage />} />
            <Route path={urls.articles} element={<ArticlesPageContainer />} />
            <Route path={urls.articleDetails(':id')} element={<ArticleDetailsPageContainer />} />
            <Route path={urls.playerDetails(':id')} element={<PlayerDetailsPageContainer />} />
            <Route path={urls.coachDetails(':id')} element={<CoachDetailsPageContainer />} />
            <Route
              path={urls.personDetails(':id')}
              element={
                <>
                  <MaintancePage />
                  {/* TODO: <PersonDetailsPageContainer /> */}
                </>
              }
            />
            <Route
              path={urls.boardMemberDetails(':id')}
              element={<BoardMemberDetailsPageContainer />}
            />
            <Route path={urls.refereeDetails(':id')} element={<RefereeDetailsPageContainer />} />
            <Route path={urls.matchDetails(':id')} element={<MatchDetailsPageContainer />} />
            <Route path={urls.stadiumDetails(':id')} element={<StadiumPage />} />
            <Route
              path={urls.currentCompetitionsDetails}
              element={<CurrentCompetitionsDetailsPageContainer />}
            />
            <Route
              path={urls.currentCompetitionsSchedule}
              element={<CurrentCompetitionsSchedulePageContainer />}
            />
            <Route
              path={urls.currentCompetitionsTable}
              element={<CurrentCompetitionsTablePageContainer />}
            />
            <Route
              path={urls.competitionsDetails(':leagueId', ':seasonId')}
              element={<CompetitionsDetailsPageContainer />} // TODO: zmienić nazwę na LeagueSeason itp. w innych miejscach i przypadkach też
            />
            <Route
              path={urls.competitionsSchedule(':leagueId', ':seasonId')}
              element={<CompetitionsSchedulePageContainer />}
            />
            <Route
              path={urls.competitionsTable(':leagueId', ':seasonId')}
              element={<TablePageContainer />}
            />
            <Route
              path={urls.playerSeasonStatistics(':playerId', ':seasonId')}
              element={<PlayerSeasonStatisticsPageContainer />}
            />
            <Route
              path={urls.coachSeasonStatistics(':coachId', ':seasonId')}
              element={<CoachSeasonStatisticsPageContainer />}
            />
            <Route
              path={urls.refereeSeasonStatistics(':refereeId', ':seasonId')}
              element={
                <>
                  <MaintancePage />
                  {/* TODO: <RefereeCompetitionsStatisticsPageContainer /> */}
                </>
              }
            />
            <Route
              path={urls.teamSeasonStatistics(':teamId', ':seasonId')}
              element={<TeamSeasonsStatisticsPageContainer />}
            />
            <Route path={urls.myTeamDetails} element={<MyTeamDetailsPageContainer />} />
            <Route
              path={urls.myTeamPlayers}
              element={<MyTeamPlayersInCurrentSeasonPageContainer />}
            />
            <Route path={urls.teamDetails(':id')} element={<TeamDetailsPageContainer />} />
            <Route
              path={urls.teamPlayers(':id')}
              element={<TeamPlayersInCurrentSeasonPageContainer />}
            />
            <Route path={urls.myClubDetails} element={<MyClubDetailsPageContainer />} />
            <Route path={urls.myClubContact} element={<ContactPageContainer />} />
            <Route path={urls.myClubHistory} element={<HistoryPageContainer />} />
            <Route
              path={urls.myClubStadiumDescription}
              element={<StadiumDescriptionPageContainer />}
            />
            <Route path={urls.myClubBoardMembers} element={<BoardMembersPageContainer />} />
            <Route path={urls.myClubPartners} element={<PartnersPageContainer />} />
            <Route path={urls.myClubSponsors} element={<SponsorsPageContainer />} />
            <Route path={urls.clubDetails(':id')} element={<ClubDetailsPageContainer />} />
            <Route path={urls.clubContact(':id')} element={<ContactPageContainer />} />
            <Route path={urls.clubHistory(':id')} element={<HistoryPageContainer />} />
            <Route
              path={urls.clubStadiumDescription(':id')}
              element={<StadiumDescriptionPageContainer />}
            />
            <Route path={urls.clubBoardMembers(':id')} element={<BoardMembersPageContainer />} />
            <Route path={urls.clubPartners(':id')} element={<PartnersPageContainer />} />
            <Route path={urls.clubSponsors(':id')} element={<SponsorsPageContainer />} />
            <Route path={urls.gallery} element={<GalleryPageContainer />} />
            {/* <Route
              path={urls.galleryAlbum(':id')}
              element={{// <AlbumPage /> }}
            /> */}
            <Route path={urls.statistics} element={<StatisticsPage />} />
            {/* TODO: <Route
            path={urls.queue(':id')}
            element={<QueueDetailsPageContainer />}
          />
          <Route
            path={urls.round(':id')}
            element={<RoundDetailsPageContainer />}
          />
          <Route
            path={urls.league(':id')}
            element={<LeagueDetailsPageContainer />}
          />
          <Route
            path={urls.myLeague}
            element={<MyLeagueDetailsPageContainer />}
          /> */}
          </Route>

          <Route path="/error-test-page" element={<ErrorTestPage />} />
          <Route path="/test-page" element={<TestPage />} />
          <Route path={urls.maintance} element={<MaintancePage />} />
          <Route path="*" element={<NotFoundPage />} />

          {/* TODO: czy to działa? czy trzeba dodać coś jak path="*"? */}
          <Route element={<ErrorPage />} />
        </Routes>
      </HistoryRouter>
    </div>
  );
};

{
  /* TODO:
  <ProtectedRoute  path="/" element={<DashboardPage />} />
          <OnlyAnonymousRoute path="/login" element={<AuthPageContainer />} />
          <OnlyAnonymousRoute
            path="/reset-password"
            element={<ResetPasswordPageContainerContainer />}
          />
          <RouteWithLayout
            layout={DefaultAnonymousLayout}
            path="/set-new-password/:token"
            element={<SetNewPasswordPageContainer />}
            oneOfPermissions={[permissions.DEP_ADMIN, permissions.EMPLOYEE]}
          />
          <ProtectedRoute  path="/my-profile" element={<MyProfilePage />} /> */
}

export default memo(Router);
