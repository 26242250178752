import isEmpty from 'lodash/isEmpty';
import i18n from 'i18n';

import { useAppSelector } from 'store';
import cardTypes from 'consts/cardTypes';
import matchEventTypesIds from 'consts/matchEventTypesIds';
import matchResultTypes from 'consts/matchResultTypes';
import playerPositionsIds from 'consts/playerPositions';
import systemParameterIds from 'consts/systemParameterIds';
import { guidRegexForUrl } from 'utils/regex';
import { getSystemParameterValue } from 'utils/systemParameterProvider';

export const getPlayerPositionColor = positionId => {
  switch (positionId) {
    case playerPositionsIds.goalkeeperId:
      return 'position-dark';
    case playerPositionsIds.defenderId:
      return 'position-blue';
    case playerPositionsIds.midfielderId:
      return 'position-green';
    case playerPositionsIds.strikerId:
      return 'position-orange';
    default:
      return 'position-dark';
  }
};

export const getMatchStatusName = status => {
  switch (status) {
    case matchResultTypes.win:
      return i18n.t('Win');
    case matchResultTypes.loss:
      return i18n.t('Loss');
    case matchResultTypes.draw:
      return i18n.t('Draw');
    case matchResultTypes.next:
      return i18n.t('NextMatch');
    default:
      return '';
  }
};

export const getMatchesCountDescription = status => {
  switch (status) {
    case matchResultTypes.win:
      return i18n.t('WinsQuantity');
    case matchResultTypes.loss:
      return i18n.t('DrawsQuantity');
    case matchResultTypes.draw:
      return i18n.t('LostsQuantity');
    default:
      return '';
  }
};

// TODO: może przenieść do arrayUtils
export const getArrayWithLength = arrayLength => [...new Array(arrayLength).keys()];

export const removeGuidFromUrl = url => url && url.replace(guidRegexForUrl, '');

export const isMyTeam = teamId => {
  const myTeamId = useAppSelector(state =>
    getSystemParameterValue(state, systemParameterIds.myTeamId)
  );

  return teamId === myTeamId;
};

export const isMyClub = clubId => {
  const myClubId = useAppSelector(state =>
    getSystemParameterValue(state, systemParameterIds.myClubId)
  );

  return clubId === myClubId;
};

// TODO: zakomentowany kod poniżej nie działa, bo leci błąd:
// Uncaught Error: Invalid hook call. Hooks can only be called inside of the body of a function component. This could happen for one of the following reasons:
// 1. You might have mismatching versions of React and the renderer (such as React DOM)
// 2. You might be breaking the Rules of Hooks
// 3. You might have more than one copy of React in the same app

// TODO: zastanowić się czy da się jakoś w podobny może sposób porozdzielać te metody

// TODO: zastanowić się czy nie przenieść do jakiegoś pliku systemParametersUtils, i też zastanowić się nad stworzeniem jakiegoś business utils czy coś, żeby tutaj były tylko commonowe, nie zależne od biznesu funkcje

// export const getMyTeamId = useAppSelector(state =>
//   getSystemParameterValue(state, systemParameterIds.myTeamId)
// );

// export const isMyTeam = teamId => teamId === getMyTeamId();

// export const getMyClubId = useAppSelector(state =>
//   getSystemParameterValue(state, systemParameterIds.myClubId)
// );

// export const isMyClub = clubId => clubId === getMyClubId();

export const isExactCurrentRoute = (pathName, routeName) => pathName === routeName;

export const isExactCurrentSubRoute = (pathName, routeName) => pathName === routeName;

export const getPositionSortingValue = position => {
  switch (position) {
    case playerPositionsIds.goalkeeperId:
      return 0;
    case playerPositionsIds.defenderId:
      return 1;
    case playerPositionsIds.midfielderId:
      return 2;
    case playerPositionsIds.strikerId:
    default:
      return 3;
  }
};

export const comparePlayersPositions = (firstPlayer, secondPlayer) =>
  getPositionSortingValue(firstPlayer.position.id) -
  getPositionSortingValue(secondPlayer.position.id);

export const mapPlayersByPosition = players =>
  !isEmpty(players) && [...players].sort(comparePlayersPositions);

// TODO: write tests
export const isNullOrUndefined = value => value === null || value === undefined;

// TODO: write tests
export const isNotNullAndNotUndefined = value => !isNullOrUndefined(value);

export const withAlternativeSign = (value, displayValue = true) =>
  !isNullOrUndefined(value) && displayValue ? value : '?';

export const collapseElement = (element, onCollapse, duration = 300, delay = 200) => {
  if (!element) {
    return;
  }

  const height = element.scrollHeight;
  const { style } = element;

  setTimeout(() => {
    requestAnimationFrame(() => {
      style.minHeight = 'initial';
      style.maxHeight = `${height}px`;
      style.transition = `all ${duration}ms`;

      requestAnimationFrame(() => {
        style.maxHeight = '0';
        style.padding = '0';
        style.margin = '0';
        setTimeout(onCollapse, duration);
      });
    });
  }, delay);
};

export const openExternalUrl = url => window.open(url, '__blank');

export const sortEvents = (previousEvent, nextEvent) => {
  if (previousEvent.minute !== nextEvent.minute) {
    return previousEvent.minute - nextEvent.minute;
  }

  const isBothEventsAreCardEvents =
    isNotNullAndNotUndefined(previousEvent.eventTypeId === matchEventTypesIds.givenCard) &&
    isNotNullAndNotUndefined(nextEvent.eventTypeId === matchEventTypesIds.givenCard);

  if (isBothEventsAreCardEvents) {
    if (previousEvent.cardType === cardTypes.yellow && nextEvent.cardType === cardTypes.red) {
      return -1;
    }

    if (previousEvent.cardType === cardTypes.red && nextEvent.cardType === cardTypes.yellow) {
      return 1;
    }
  }

  return 0;
};
