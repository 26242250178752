import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { ISimpleTeamModel } from 'models/businessCommon.models';

import urls from 'consts/urls';
import MatchStatus from 'components/MatchStatus';
import Tooltip from 'components/Tooltip';

import styles from './LastMatch.module.scss';
import LastMatchInfo from './LastMatchInfo';

type Props = {
  id: string;
  host: ISimpleTeamModel;
  guest: ISimpleTeamModel;
  date: Date;
  hostGoals: number;
  guestGoals: number;
  status: string;
  setActiveTeamId: (teamId: string) => void;
  clearActiveTeamId: () => void;
};

const LastMatch = ({
  id,
  host,
  guest,
  date,
  hostGoals,
  guestGoals,
  status,
  setActiveTeamId,
  clearActiveTeamId
}: Props) => {
  const tooltipClassName = cx(styles.match_container);

  const handleMouseEnter = () => setActiveTeamId(guest.id);
  const handleMouseLeave = () => clearActiveTeamId();

  return (
    <Tooltip
      className={tooltipClassName}
      html={
        <LastMatchInfo
          host={host}
          guest={guest}
          hostGoals={hostGoals}
          guestGoals={guestGoals}
          date={date}
        />
      }
    >
      <Link
        className={styles.match_link}
        to={urls.matchDetails(id)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <MatchStatus status={status} />
      </Link>
    </Tooltip>
  );
};

export default memo(LastMatch);
