import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { matchPropTypes } from 'propTypes';
import urls from 'consts/urls';
import { isNotNullAndNotUndefined, withAlternativeSign } from 'utils/utils';

import Column from './Column';
// import { icons } from 'consts/icons';
import DateInfo from './DateInfo';
// import Icon from './Icon';
import TeamLink from './Links/TeamLink';
import MatchStatus from './MatchStatus';
import styles from './MobileMatchRow.module.scss';
// import Tooltip from './Tooltip';
import Row from './Row';
// import MatchResults from './MatchResults';
import TimeInfo from './TimeInfo';

const propTypes = { ...matchPropTypes, className: PropTypes.string };

const MobileMatchRow = ({
  id,
  host,
  guest,
  hostGoals,
  guestGoals,
  hostFirstHalfGoals,
  guestFirstHalfGoals,
  date,
  // comment,
  status,
  // isWalkoverForGuest,
  // isWalkoverForHost,
  // walkoverCause
  withStatus
  // withStatusAndPlayerStatistics,
  // teamIdContext,
  // className
}) => (
  <Row className={styles.row}>
    <Column className={styles.row_teams_column}>
      <TeamLink {...host} />
      <TeamLink {...guest} />
    </Column>

    <Column className={styles.row_link_column}>
      <Link to={urls.matchDetails(id)} className={styles.link}>
        <Column className={styles.results_column}>
          <span className={styles.results_column_goals}>{hostGoals}</span>
          <span className={styles.results_column_goals}>{guestGoals}</span>
        </Column>

        <Column className={styles.half_results_column}>
          {isNotNullAndNotUndefined(hostFirstHalfGoals) &&
          isNotNullAndNotUndefined(guestFirstHalfGoals) ? (
            <>
              <span className={styles.half_results_column_goals}>
                {withAlternativeSign(hostFirstHalfGoals)}
              </span>
              <span className={styles.half_results_column_goals}>
                {withAlternativeSign(guestFirstHalfGoals)}
              </span>
            </>
          ) : (
            ''
          )}
        </Column>

        <Column className={styles.date_time_column}>
          <DateInfo date={date} withoutIcon />
          <TimeInfo date={date} withoutIcon />
        </Column>

        {withStatus && (
          <Column className={styles.status_column}>
            <MatchStatus status={status} />
          </Column>
        )}
      </Link>
    </Column>
  </Row>
);

MobileMatchRow.propTypes = propTypes;

export default memo(MobileMatchRow);
