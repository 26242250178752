import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { icons } from 'consts/icons';
import { useIsCurrentlyOnUrl } from 'utils/customHooks';

import styles from './MobileSubmenuLink.module.scss';

const propTypes = {
  address: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func
};

const MobileSubmenuLink = ({ address, label, className, onClick }) => {
  const isActiveUrl = useIsCurrentlyOnUrl(address);
  const linkClassName = cx(className, {
    [styles.subLink_active]: isActiveUrl
  });
  const iconClassName = cx(icons.caretRight, styles.subLink_icon);

  return (
    <li className={styles.subLink}>
      <Link to={address} className={linkClassName} onClick={onClick}>
        <i className={iconClassName} />
        {label}
      </Link>
    </li>
  );
};

MobileSubmenuLink.propTypes = propTypes;

export default memo(MobileSubmenuLink);
