// TODO: if auth works, uncomment

// import { useDispatch, useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';

// import { icons } from 'consts/icons';
// import urls from 'consts/urls';
// import { logout } from 'modules/auth/actions';
// import UserWidget from 'modules/auth/UserWidget';

// import DropdownItem from './DropdownItem';
// import UserAction from './UserAction';
import React, { memo } from 'react';

import LanguageBar from './LanguageBar';
import styles from './UserSection.module.scss';

const UserSection = () => {
  // TODO: if auth works, uncomment

  // const { t } = useTranslation();

  // const dispatch = useDispatch();
  // const userData = useAppSelector(state => state.auth && state.auth.currentUser);

  // // TODO: userData -> isAuth/isLogin etc. i wyciagnac do globalnego utilsa, selektora
  // // TODO: dodać do dropdowna editProfile

  // const handleLogout = () => dispatch(logout());

  return (
    <div className={styles.user_section}>
      <LanguageBar />

      {/* TODO: if auth works, uncomment {!userData ? (
        <UserAction
          renderActionContainer={className => (
            <Link to={urls.login} className={className}>
              {t('Login')}
              <i className={icons.login} />
            </Link>
          )}
        />
      ) : (
        <UserAction
          renderDropdown={() => (
            <DropdownItem
              renderItem={itemClassName => (
                <button className={itemClassName} onClick={handleLogout}>
                  {t('LogOut')}
                </button>
              )}
            />
          )}
        >
          <UserWidget avatar={undefined} userData={userData} />
        </UserAction>
      )} */}
    </div>
  );
};

export default memo(UserSection);
