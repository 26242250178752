import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { simpleTeamPropTypesShape } from 'propTypes';
import { icons } from 'consts/icons';
import urls from 'consts/urls';
import { isMyTeam, isNullOrUndefined } from 'utils/utils';
import Card from 'components/Card';
import Column from 'components/Column';
import DateInfo from 'components/DateInfo';
import TimeInfo from 'components/TimeInfo';

import styles from './CardMatch.module.scss';
import CardMatchPlaceholder from './CardMatchPlaceholder';
import MatchCountDown from './MatchCountDown';

const propTypes = {
  nextMatch: PropTypes.bool,
  match: PropTypes.exact({
    id: PropTypes.string,
    host: simpleTeamPropTypesShape,
    guest: simpleTeamPropTypesShape,
    date: PropTypes.string,
    hostGoals: PropTypes.number,
    guestGoals: PropTypes.number
  }),
  isMatchLoaded: PropTypes.bool,
  withTeamName: PropTypes.bool,
  withEmphasisMyTeam: PropTypes.bool
};

const CardMatch = ({
  nextMatch,
  match,
  isMatchLoaded,
  withTeamName = true,
  withEmphasisMyTeam = true
}) => {
  const { t } = useTranslation();

  const matchBackgroundClass = nextMatch ? 'green' : 'dark-opaque';

  const getGoalsToDisplay = goalsCount =>
    nextMatch || isNullOrUndefined(goalsCount) ? '?' : goalsCount;

  const displayMatch = !isEmpty(match) && isMatchLoaded;

  const noLogoIconClassName = cx(icons.image, styles.match_noLogoIcon);

  const hostTeamNameClassName = cx(styles.match_team_name, {
    [styles.match_team_name_emphasized]: isMyTeam(match.host?.id) && withEmphasisMyTeam
  });

  const guestTeamNameClassName = cx(styles.match_team_name, {
    [styles.match_team_name_emphasized]: isMyTeam(match.guest?.id) && withEmphasisMyTeam
  });

  return displayMatch ? (
    <Card
      renderTitle={() => (
        <>
          {/* TODO: zastanowić się, może zrobić odliczanie ile czasu minęło od poprzedniego meczu do teraz */}
          {nextMatch ? t('NextMatch') : t('PreviousMatch')}
          {nextMatch && !!match.date && <MatchCountDown destinationTime={match.date} />}
        </>
      )}
      renderFooter={() => (
        <Link to={urls.matchDetails(match.id)} title={t('GoToTheMatchDetails')}>
          {t('MatchDetails')}
        </Link>
      )}
      titleBackground={matchBackgroundClass}
      footerBackground={matchBackgroundClass}
    >
      <div className={styles.match}>
        <Column className={styles.match_left}>
          <div className={styles.match_time}>
            <DateInfo date={match.date} withDayName shouldWrapDayNameToNextLine />
          </div>
          {/* TODO: spróbować użyć <TeamEntry> i może <MatchResults> lub <MatchScoreSection> lub <MatchScorePoints> może jakoś ujednolicić te komponenty z MatchDetailsCard i przenieść do common? */}
          <Link
            className={styles.match_logo_container}
            to={urls.teamDetails(match.host.id)}
            title={`${t('GoToTheClubSubpage')} ${match.host.name}`}
          >
            {withTeamName ? <div className={hostTeamNameClassName}>{match.host.name}</div> : ''}

            {match.host.logo ? (
              <img src={match.host.logo} alt={match.host.name} className={styles.match_logo} />
            ) : (
              <i className={noLogoIconClassName} />
            )}
          </Link>
          <span className={styles.match_score}>{getGoalsToDisplay(match.hostGoals)}</span>
        </Column>
        <Column className={styles.match_middle}>
          <span className={styles.match_score_divider}>-</span>
        </Column>
        <Column className={styles.match_right}>
          <div className={styles.match_time}>
            <TimeInfo date={match.date} />
          </div>
          <Link
            className={styles.match_logo_container}
            to={urls.teamDetails(match.guest.id)}
            title={`${t('GoToTheClubSubpage')} ${match.guest.name}`}
          >
            {withTeamName ? <div className={guestTeamNameClassName}>{match.guest.name}</div> : ''}

            {match.guest.logo ? (
              <img src={match.guest.logo} alt={match.guest.name} className={styles.match_logo} />
            ) : (
              <i className={noLogoIconClassName} />
            )}
          </Link>
          <span className={styles.match_score}>{getGoalsToDisplay(match.guestGoals)}</span>
        </Column>
      </div>
    </Card>
  ) : isMatchLoaded ? (
    <Card
      renderTitle={() => (nextMatch ? t('NextMatch') : t('PreviousMatch'))}
      titleBackground={matchBackgroundClass}
      footerBackground={matchBackgroundClass}
    >
      <div className={styles.noInformationAboutMatch}>{t('NoInformationAboutMatch')}</div>
    </Card>
  ) : (
    <CardMatchPlaceholder nextMatch={nextMatch} titleBackground={matchBackgroundClass} />
  );
};

CardMatch.propTypes = propTypes;

export default memo(CardMatch);
