import React, { memo, ReactNode } from 'react';
import cx from 'classnames';

import { colorsType, EColors } from 'consts/colors';

import styles from './CardTitle.module.scss';

type Props = {
  background: colorsType;
  children: ReactNode;
};

const CardTitle = ({ background = EColors.green, children }: Props) => {
  const titleClassName = cx(styles.title, {
    [`background-${background}`]: background
  });

  return <h3 className={titleClassName}>{children}</h3>;
};
export default memo(CardTitle);
