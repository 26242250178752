import React, { memo, ReactElement, ReactNode } from 'react';
import { Tooltip as TooltipBase } from 'react-tippy';

// TODO:check file: typeScript.hacks.ts

type Props = { children: ReactNode; html: string | ReactElement; className?: string };

const Tooltip = ({ children, html, ...others }: Props) => (
  <TooltipBase
    position="bottom-end"
    trigger="mouseenter"
    animation="perspective"
    delay={150}
    hideDelay={250}
    distance={15}
    inertia
    arrow
    html={typeof html === 'string' ? <>{html}</> : html}
    {...others}
  >
    {children}
  </TooltipBase>
);

export default memo(Tooltip);
