import actionTypes from './actionTypes';

const initialState = {
  articles: undefined,
  table: undefined,
  isPreviousMatchLoaded: false,
  previousMatch: undefined,
  isNextMatchLoaded: false,
  nextMatch: undefined
};

export default function homePageReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.STORE_HOME_PAGE_ARTICLES:
      return {
        ...state,
        articles: [...action.data]
      };
    case actionTypes.STORE_HOME_PAGE_TABLE:
      return {
        ...state,
        table: {
          ...action.data
        }
      };
    case actionTypes.SET_IS_PREVIOUS_MATCH_LOADED_FALSE:
      return {
        ...state,
        isPreviousMatchLoaded: false
      };
    case actionTypes.STORE_HOME_PAGE_PREVIOUS_MATCH:
      return {
        ...state,
        previousMatch: action.data,
        isPreviousMatchLoaded: true
      };
    case actionTypes.SET_IS_NEXT_MATCH_LOADED_FALSE:
      return {
        ...state,
        isNextMatchLoaded: false
      };
    case actionTypes.STORE_HOME_PAGE_NEXT_MATCH:
      return {
        ...state,
        nextMatch: action.data || undefined, // TODO: jeśli nie ma następnego meczu to backend zwraca 204 a w action.data jest ""
        isNextMatchLoaded: true
      };
    default:
      return state;
  }
}
