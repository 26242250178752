import React, { Children, createRef, memo, useEffect, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import ArrayUtils from 'utils/array.utils';

import styles from './ContentRevealer.module.scss';

type Props = {
  isVisible: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  className: string;
  visibleClassName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutableValues?: any[];
  isStyleDisplayContents?: boolean;
};

// TODO: Vorbert -> użyć classNames dla zarządzania tym cos się dzieje z nazwami klas
// TODO: Vorbert -> zaktualizować komponent, tak żeby bazował na właściwości "scrollHeight";

const heightPropertyNames = [
  'height',
  'margin-top',
  'margin-bottom',
  'padding-top',
  'padding-bottom'
];

const ContentRevealer = ({
  children,
  isVisible,
  className,
  visibleClassName,
  mutableValues = [],
  isStyleDisplayContents
}: Props) => {
  // TODO: sprawdzić czy napewno nie potrzeba 'childrenHeight' i jak nie to usunąć logikę

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [childrenHeight, setChildrenHeight] = useState(0);
  // note: Tworze tyle referencji do dzieci, ile ich zostaje wrzuconych do komponentu
  const itemsReference = useRef([...Children.toArray(children).map(() => createRef())]);

  const getElementPropertyValue = (element: Element, propertyName: string) =>
    parseFloat(window.getComputedStyle(element).getPropertyValue(propertyName));

  const getElementHeight = (element: Element) =>
    heightPropertyNames
      .map(propertyName => getElementPropertyValue(element, propertyName))
      .reduce((previous, next) => previous + next);

  const getChildrenHeight = () => {
    const items = itemsReference.current.map(itemRef => itemRef.current);

    let itemsHeight = 0;

    if (ArrayUtils.isNotEmpty(items)) {
      itemsHeight = items
        .map(item => getElementHeight(item as Element))
        .reduce((previous, next) => previous + next);
    }

    return itemsHeight;
  };

  useEffect(
    () => {
      setChildrenHeight(getChildrenHeight() + 10);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      !isEmpty(mutableValues) && setChildrenHeight(getChildrenHeight() + 10);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...mutableValues]
  );

  return (
    <div
      className={`${styles.content_revealer} ${!isVisible ? styles.hidden : ''} ${className} ${
        isVisible && visibleClassName ? visibleClassName : ''
      }`}
      style={{
        // height: `${childrenHeight}px`,
        display: isStyleDisplayContents && isVisible ? 'contents' : 'block'
      }}
    >
      {Children.toArray(children).map((child, childIndex) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        React.cloneElement(child as any, {
          ref: itemsReference.current[childIndex]
        })
      )}
    </div>
  );
};

export default memo(ContentRevealer);
