import React, { memo } from 'react';

import { icons } from 'consts/icons';

import DateTimeInfo from '../DateTimeInfo';
import InfoWithIcon from '../InfoWithIcon';
import Row from '../Row';
import styles from './ArticleHeader.module.scss';

type Props = {
  author: string;
  title: string;
  createdDate: Date;
};

const ArticleHeader = ({ author, title, createdDate }: Props) => (
  <div className={styles.header}>
    {(author || createdDate) && (
      <Row className={styles.info_container}>
        {author && (
          <InfoWithIcon icon={icons.userOutline} className={styles.info_author}>
            {author}
          </InfoWithIcon>
        )}
        <DateTimeInfo date={createdDate} />
      </Row>
    )}
    <h2 className={styles.header_title}>{title}</h2>
  </div>
);

export default memo(ArticleHeader);
